import React, { useState } from 'react';
import styled from 'styled-components';
import execute from "hooks/execute";
import { protectedResources } from 'config/msalConfig';


function ModulePageSearch({ setSearchResults }) {
    const [module, setOrganization] = useState('');
    const [addedby, setCustomer] = useState('');

    const handleSearch = async () => {
        var response = await execute("GET", `${protectedResources.api.baseEndPoint}/modules?${new URLSearchParams({module, addedby})}`);
        if(response){
            if(response.success){
                setSearchResults(response.data);
            }else{
                console.error('Error fetching data:', response.message);
            }
        }else{
            console.error('Error fetching data: No response');
        }
    };

    const handleOrganizationChange = (e) => {
        console.log('Event object:', e);
        console.log('Customer input:', e.target.value);
        setOrganization(e.target.value);
    };

    const handleCustomerChange = (e) => {
        console.log('Event object:', e);
        console.log('Customer input:', e.target.value);
        setCustomer(e.target.value);
    };

    return (
        <SearchParameterContainer>
            <SearchParameterTitle>Search Parameter</SearchParameterTitle>
            <SearchParameterFields>
                <InputField
                    label="Module Name"
                    placeholder="Enter a name or email address"
                    value={module}
                    onChange={handleOrganizationChange}
                />
                <InputField
                    label="AddedBy"
                    placeholder="Enter a name or email address"
                    value={addedby}
                    onChange={handleCustomerChange}
                />
                <SearchButton onClick={handleSearch}>Search</SearchButton>
            </SearchParameterFields>
        </SearchParameterContainer>
    );
}



function InputField({ label, placeholder, value, onChange }) {
    return (
        <InputFieldContainer>
            <InputFieldLabel>{label}</InputFieldLabel>
            <InputFieldWrapper
                type="text"
                placeholder={placeholder}
                value={value}
                onChange={onChange}
            />
        </InputFieldContainer>
    );
}

const SearchParameterContainer = styled.section`
    padding: 20px;
    background-color: #f8f8f8;
    border-radius: 0px;
    margin: 20px;
    width: 130%;
    margin-left: -150px;
    box-shadow: 0 2px 4px rgba(0,0,0,0.1);
`;

const SearchParameterTitle = styled.h2`
    color: var(--bicollab-primary-color-blue-900, #002c57);
    letter-spacing: 0.4px;
    font: 500 16px/100% Ubuntu, sans-serif;
`;

const SearchParameterFields = styled.div`
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    gap: 10px;
    margin-top: 24px;
`;


const InputFieldLabel = styled.label`
    color: #767676;
    text-align: left;
    font: 500 14px Ubuntu, sans-serif;
`;

const InputFieldWrapper = styled.input`
    border-radius: 2px;
    border: 1px solid rgba(187, 187, 188, 1);
    background-color: var(--bicollab-black-white-white, #fff);
    margin-top: 13px;
    padding: 10px 21px;
    color: var(--bicollab-black-white-black-300, #bbbbbc);
    font: 400 16px Ubuntu, sans-serif;
`;

const InputFieldContainer = styled.div`
    flex: 1;
    display: flex;
    flex-direction: column;
    padding: 0 5px;
`;

const SearchButton = styled.button`
    border-radius: 4px;
    background-color: var(--bicollab-secondary-color-orange-700, #f16622);
    align-self: flex-end;
    margin-top: 28px;
    padding: 10px 18px;
    color: var(--bicollab-black-white-white, #fff);
    white-space: nowrap;
    font: 500 20px Ubuntu, sans-serif;
`;
export default ModulePageSearch;