import { App, Button, Checkbox, DatePicker, Form, Input, InputNumber, Modal, Space, Switch } from "antd";
import { CloseSquareFilled, MinusCircleOutlined, PlusOutlined, SaveFilled } from '@ant-design/icons';
import { useEffect } from "react";
import execute from "hooks/execute";
import { protectedResources } from "config/msalConfig";

export const DataEditor = ({ isOpen, dataItem, onDataEditorFinished, onDataEditorCancelled }) => {
    const [form] = Form.useForm();
    const { message } = App.useApp();


    const handleDataEditFinished = async (values) => {
        console.log(values)
        console.log("handleDataEditFinished");
        var newData = { ...dataItem, ...values };
        console.log(newData);
        // return
        if (newData.Id) {
            // call api to update data
            const response = await execute('PUT', `${protectedResources.api.baseEndPoint}/customerRolesWithValue`, newData);
            if (response.success) {
                message.success("Customer updated successfully");
                newData = { ...newData, ...response.data };
            } else {
                message.error(response.message);
                return;
            }
        }
        else {
            // call  api to add data
            const response = await execute('POST', `${protectedResources.api.baseEndPoint}/customerRolesWithValue`, newData);
            if (response.success) {
                message.success("Customer added successfully");
                newData = { ...newData, ...response.data };
            } else {
                message.error(response.message);
                return;
            }
        }
        onDataEditorFinished(newData);
    }

    const handleDataEditCancelled = () => {
        console.log("handleDataEditCancelled");
        onDataEditorCancelled();
    }

    useEffect(() => {
        if (isOpen) {
            // console.log(dataItem)
            // const roleValues = dataItem.RoleValues.map(role => role.RoleValue);
            // console.log(roleValues)
            form.setFieldsValue({
                role_name: dataItem.Name,
                role_values: dataItem.RoleValues
            });
        }
    }, [isOpen, dataItem, form]);

    const formItemLayout = {
        labelCol: {
            xs: {
                span: 24,
            },
            sm: {
                span: 4,
            },
        },
        wrapperCol: {
            xs: {
                span: 24,
            },
            sm: {
                span: 20,
            },
        },
    };
    const formItemLayoutWithOutLabel = {
        wrapperCol: {
            xs: {
                span: 24,
                offset: 0,
            },
            sm: {
                span: 20,
                offset: 4,
            },
        },
    };

    return (
        <Modal
            open={isOpen}
            title={dataItem.Id ? "Edit Customer Role" : "Add Customer Role"}
            okText={<Space><SaveFilled />Save</Space>}
            cancelText={<Space><CloseSquareFilled />Cancel</Space>}
            okButtonProps={{ autoFocus: true, htmlType: 'submit' }} //form: 'dataEditorForm', key: 'submit'
            onCancel={handleDataEditCancelled}
            destroyOnClose
            modalRender={(dom) => (
                <div
                    style={{
                        maxHeight: "500px", // Fixed height of modal
                        overflowY: "auto", // Enable scrolling when content overflows
                    }}
                >
                <Form
                    layout="horizontal"
                    form={form}
                    name="dataEditorForm"
                    labelCol={{ span: 8 }}
                    wrapperCol={{ span: 16 }}
                    initialValues={{
                        modifier: 'public',
                    }}
                    clearOnDestroy
                    onFinish={handleDataEditFinished}
                >
                    {dom}
                </Form>
                </div>
            )}>
            <Form.Item name="Customer_ID" label="Customer ID" hidden>
                <Input />
            </Form.Item>

            {/* Role Name */}
            <Form.Item
                name="role_name"
                label="Role Name"
                rules={[{ required: true, message: 'Role Name is required' }]}
            >
                <Input placeholder="Enter role name" />
            </Form.Item>

            {/* Role Values */}
            <Form.List name="role_values">
                {(fields, { add, remove }) => (
                    <>
                        {fields.map((field, index) => (
                            <Form.Item
                                key={field.key}
                                required={false}
                                label={index === 0 ? 'Role Values' : '           '}
                            >
                                <div style={{ display: 'flex', gap: '8px', alignItems: 'center', height: "25px" }}>
                                    {/* Hidden RoleValueId */}
                                    <Form.Item
                                        name={[field.name, 'RoleValueId']}
                                        style={{ display: 'none' }}
                                    >
                                        <Input type="hidden" />
                                    </Form.Item>

                                    {/* RoleValue Input */}
                                    <Form.Item
                                        name={[field.name, 'RoleValue']}
                                        rules={[{ required: true, message: 'Please input Role Value!' }]}
                                        style={{ flex: 1, height: "100%" }}
                                    >
                                        <Input style={{height: "34px"}} placeholder={`Role Value ${index + 1}`} />
                                    </Form.Item>

                                    {/* Remove Button */}
                                    <MinusCircleOutlined
                                        style={{ color: 'red' }}
                                        onClick={() => remove(field.name)}
                                    />
                                </div>
                            </Form.Item>
                        ))}

                        {/* Add Role Value Button */}
                        <Form.Item
                            style={{ display: "flex", justifyContent: "center", width: "100%" }}
                            >
                            <Button
                                type="dashed"
                                onClick={() => add()}
                                icon={<PlusOutlined />}
                                style={{ width: '200%' }}
                            >
                                Add Role Value
                            </Button>
                        </Form.Item>
                    </>
                )}
            </Form.List>

            {/* Status Switch */}
            <Form.Item name="status" label="Status" valuePropName="checked">
                <Switch />
            </Form.Item>

            {/* All Modules Checkbox */}
            <Form.Item name="all_modules" label="All Modules" valuePropName="checked">
                <Checkbox></Checkbox>
            </Form.Item>

        </Modal>
    );
};