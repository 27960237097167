import React, { useState, useEffect } from 'react';
import execute from "hooks/execute";
import { protectedResources } from 'config/msalConfig';
import OrganizationPageHeader from './OrgPageHeader';
import OrgPageSearch from './OrgPageSearch';
import { useUser } from 'contexts/UserContext';
import OrgManagementTable from './OrgManagementTable';

function OrgManage({ userDetails }) {
  const [searchResults, setSearchResults] = useState(null); // Initialize searchResults state
  const [records, setRecords] = useState([]);


  // Function to fetch records
  // const fetchRecords = async () => {
  //   try {
  //     // Adjust the response according to your API
  //     execute("GET", `${protectedResources.api.baseEndPoint}/organizations`).then((response) => {
  //       if (response && response.success) {
  //         setRecords(response.data); // Adjust the response according to your API
  //       }
  //     });
  //   } catch (error) {
  //     console.error('Error fetching data:', error);
  //   }
  // };

  // useEffect(() => {
  //   console.log(error);
  // }, [error]);

  useEffect(() => {
    // fetchRecords();
  }, []);

  useEffect(() => {
    // Update records when searchResults changes
    if (searchResults) {
      setRecords(searchResults);
    }
  }, [searchResults]);

  // Function to handle adding a new organization
  const handleAddOrganization = () => {
    // fetchRecords(); // Re-fetch records after adding a new organization
  };


  return (
    <div 
      style={{
        width: "90%",
        marginLeft: "auto",
        marginRight: "auto",
        padding: "20px 0",
      }}
    >
      <OrganizationPageHeader onAddOrganization={handleAddOrganization} />
      {/* <OrgPageSearch setSearchResults={setSearchResults} />*/} 
      <OrgManagementTable userDetails={userDetails}/>
      {/* <OrgData searchResults={searchResults} records={records} setRecords={setRecords} fetchRecords={fetchRecords} /> Pass searchResults and records down */}
    </div>
  );
}

export default OrgManage