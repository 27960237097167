import { Button, Table } from "antd";
import { protectedResources } from "config/msalConfig";
import execute from "hooks/execute";
import { useEffect, useState } from "react";
import { FaFilePen, FaPlus, FaTrash } from "react-icons/fa6";
import DeleteConfirmationModal from "../DeleteConfirmationModal";
import { DataEditor } from "./DataEditor";
import styled from "styled-components";


const CustomerRoleValueData = ({ customerRole }) => {
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState('');
    const [data, setData] = useState([]);

    const [isDataEditorOpen, setIsDataEditorOpen] = useState(false);        
    const [editableDataItem, setEditableDataItem] = useState({});
    const [currentRecord, setCurrentRecord] = useState(null);
    const [isDeleteModalOpen, setDeleteModalOpen] = useState(false);
    

    const openEditorToAdd = () => {
        console.log("openEditorToAdd");
        setEditableDataItem({CustomerRoleId: customerRole.Id});
        setIsDataEditorOpen(true);
    };

    const openEditorToEdit = (record) => {
        console.log("edit", record);
        setEditableDataItem(record);
        setIsDataEditorOpen(true);
    };

    const columns = [
    {
        title: 'Role Value',
        dataIndex: 'Value',
        key: 'Value',
        sorter: (a, b) => a.Value.toString().localeCompare(b.Value.toString())
    },
    {
        title: 'Status',
        dataIndex: 'Status',
        key: 'Status',
        sorter: (a, b) => a.Status - b.Status,
        render: (text, record) => (
            <>
                <StatusButton active={record.Status?'true':'false'}>
                {record.Status ? 'Enabled' : 'Disabled'}
              </StatusButton> 
            </>
        )
    },
    {
        title: <Button color="default" variant="text" onClick={ openEditorToAdd } icon={<FaPlus  style={{ color: '#ffad1c' }} />} />,
        key: 'actions',
        className: "text-end",
        render: (text, record) => (
            <>                   
                <Button   color="default" variant="text" onClick={(e) => {
                    e.stopPropagation(); // Prevent row click
                    openEditorToEdit(record);
                }} icon={<FaFilePen  style={{ color: '#ffad1c' }} />} />
                                    
                <Button   color="default" variant="text" onClick={(e) => {
                    e.stopPropagation(); // Prevent row click
                    handleDelete(record);
                }} icon={<FaTrash style={{ color: '#ffad1c' }} />} />
            </>
            
        ),
    }];

    const handleDataEditFinished = (newData) => {
        console.log("handleDataEditFinished", newData, editableDataItem);
        setIsDataEditorOpen(false);
        fetchData();
    };

    const handleDataEditCancelled = () => {
        console.log("handleDataEditCancelled");
        setIsDataEditorOpen(false);
    };

    const handleDelete = (record) => {
        setCurrentRecord({ ...record });
        setDeleteModalOpen(true);
    };

    const handleConfirmDelete = async () => {
        try {
            const response = await execute('DELETE', `${protectedResources.api.baseEndPoint}/customerRoles/${customerRole.Id}/values`, {
                    customerRoleValueId: currentRecord.Id
                });
            if (response.success) {
                alert('customer role value deleted successfully.');
                await fetchData(); // Refetch data after deletion
            } else {
                setError(response.message || 'Failed to delete customer role value');
            }
        } catch (error) {
            alert('Failed to delete customer role value: ' + error.message);
        }
        setDeleteModalOpen(false);
    };

    const fetchData = async () => {
        try {
            setLoading(true);
            const response = await execute('GET', `${protectedResources.api.baseEndPoint}/customerRoles/${customerRole.Id}/values`);
            console.log('response', response);
            if (response.success && response.data.length > 0) {
                
                setData(response.data);
                setError('');
            } else {
                setData([]);
                setError(response.message || 'No customer role value found for the specified organization');
            }
            setLoading(false);
        } catch (err) {
            setError('Failed to fetch data: ' + err.message);
            setLoading(false);
        }
    };
    useEffect(() => {
        console.log(customerRole);
        if(customerRole && customerRole.Id){
            fetchData();
        }
    }, [customerRole]);


    return (
        <>
            <Table 
                rowKey={record => record.Id} 
                columns={columns} 
                dataSource={data}  
                loading={loading}
                pagination={false} />
                        { isDataEditorOpen ? 
                <DataEditor 
                    isOpen={isDataEditorOpen} 
                    dataItem={editableDataItem} 
                    onDataEditorFinished={handleDataEditFinished} 
                    onDataEditorCancelled={handleDataEditCancelled} /> 
                    : null }
            {isDeleteModalOpen && <DeleteConfirmationModal isOpen={isDeleteModalOpen} onClose={() => setDeleteModalOpen(false)} record={currentRecord} onDeleteConfirmed={handleConfirmDelete} />}    
        </>
    );

};

const StatusButton = styled.span`
  display: inline-block;
  width: 80px;  // Set a fixed width
  height: 25px; // Set a fixed height
  line-height: 25px; // Use line height equal to height for vertical centering
  color: white;
  background-color: ${props => props.active === 'true' ? '#4CAF50' : '#808080'};  // Grey color for inactive
  padding: 0;
  text-align: center; // Center text horizontally
  border-radius: 5px;
  cursor: default;
`;

export default CustomerRoleValueData;