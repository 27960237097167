import { App, DatePicker, Form, Input, InputNumber, Modal, Space } from "antd";
import { CloseSquareFilled, SaveFilled } from '@ant-design/icons';
import { useEffect, useState } from "react";
import execute from "hooks/execute";
import { protectedResources } from "config/msalConfig";
import { get } from "lodash";

export const DataEditor = ({isOpen, dataItem, onDataEditorFinished, onDataEditorCancelled }) => {
    const [form] = Form.useForm();
    const { message } = App.useApp();


    const [emailError, setEmailError] = useState(null);
    

    const checkIfUserWithEmailExists = async (email) => {
          try {
            const response = await execute('GET',`${protectedResources.api.baseEndPoint}/adminUsers/exists/${email}`);
            if(response.success){        
              return response.data;
            }else{
            //   alert(response.message || 'Failed to check if user exists');
              return { exists: false };
            }
          } catch (error) {
            console.error('Error checking if user exists:', error);
            alert('Error checking if user exists: ' + error.message);
            return { exists: false };
          }
    };
      
    const ValidateEmailDomain = async (email) => {
          console.log('custEmail:', email);
          if (!email) {
            setEmailError('Email is required');
          }    
          //  check if email is valid and suffix with domain
          else if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email)) {
            setEmailError('Invalid email format');
          } else{ 
            setEmailError('Checking if user exists...');
            var emailExists = await checkIfUserWithEmailExists(email);
            if(emailExists.exists){
              setEmailError('A user with this email already exists');
            }else {
              setEmailError(null);
              return true;
            }
          }
          return false;
    };

    const handleDataEditFinished = async (values) => {
        console.log("handleDataEditFinished");
        var newData = {...dataItem, ...values};
        console.log(newData);

        if(newData.Id){
          const response = await execute('PUT',`${protectedResources.api.baseEndPoint}/modules`, newData);
          if(response.success){   
              message.success("Module Updated successfully");
              newData = {...newData, ...response.data};
          }else{
              message.error(response.message);
              return;
          }
        }else{
          const response = await execute('POST',`${protectedResources.api.baseEndPoint}/modules`, newData);
          if(response.success){   
              message.success("Module added successfully");
              newData = {...newData, ...response.data};
          }else{
              message.error(response.message);
              return;
          }
        }
        
        // call  api to add data
        
        
        onDataEditorFinished(newData);
    }

    const handleDataEditCancelled = () => {
        console.log("handleDataEditCancelled");
        onDataEditorCancelled();
    }
    
    useEffect(() => {
      console.log(dataItem)
        if(isOpen){
            form.setFieldsValue({
              moduleName: dataItem.ModuleName,
              short: dataItem.ShortName,
              price: dataItem.Price,
              icon: dataItem.Icon,
              order: dataItem.Order
            });
        }
    }, [isOpen, dataItem, form]);

    return (
        <Modal
            open={isOpen}
            title="New Admin User"
            okText={<Space><SaveFilled />Save</Space> }
            cancelText={<Space><CloseSquareFilled />Cancel</Space> }
            okButtonProps={{ autoFocus: true, htmlType: 'submit' }} //form: 'dataEditorForm', key: 'submit'
            onCancel={handleDataEditCancelled}
            destroyOnClose
            modalRender={(dom) => (
                <Form
                  layout="horizontal"
                  form={form}
                  name="dataEditorForm"
                  labelCol={{ span: 8 }}
                  wrapperCol={{ span: 16 }}
                  initialValues={{
                    modifier: 'public',
                  }}
                  clearOnDestroy
                  onFinish={handleDataEditFinished}
                >
                  {dom}
                </Form>
            )}>            


            <Form.Item
                name="moduleName"
                label="Module Name"
                rules={[{ required: true, message: 'Module Name is required' }]}>
                <Input />
            </Form.Item>

            <Form.Item
                name="short"
                label="Short Name"
                rules={[{ required: true, message: 'Short Name is required' }]}>
                <Input />
            </Form.Item>

            <Form.Item
                name="price"
                label="Price"
                rules={[{ required: true, message: 'Price is required' }]}>
                <Input type="number" />
            </Form.Item>

            <Form.Item
                name="icon"
                label="Icon"
                rules={[{ required: true, message: 'Icon is required' }]}>
                <Input defaultValue="fa-solid" />
            </Form.Item>

            <Form.Item
                name="order"
                label="Display Order"
                rules={[{ required: true, message: 'Display Order is required' }]}>
                <Input type="number" />
            </Form.Item>
           
        </Modal>
    );
};