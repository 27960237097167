import { App, DatePicker, Form, Input, InputNumber, Modal, Space, Switch } from "antd";
import { CloseSquareFilled, SaveFilled } from '@ant-design/icons';
import { useEffect } from "react";
import execute from "hooks/execute";
import { protectedResources } from "config/msalConfig";

export const DataEditor = ({isOpen, dataItem, onDataEditorFinished, onDataEditorCancelled }) => {
    const [form] = Form.useForm();
    const { message } = App.useApp();

    const handleDataEditFinished = async (values) => {
        console.log("handleDataEditFinished");
        var newData = {...dataItem, ...values};
        console.log(newData);
        if(newData.Id){
            // call api to update data
            const response = await execute('PUT',`${protectedResources.api.baseEndPoint}/customerRoles/${dataItem.CustomerRoleId}/values`, newData );
            if(response.success){
                message.success("Customer Role Value updated successfully");
                newData = {...newData, ...response.data};
            }else{
                message.error(response.message);
                return;
            }
        }
        else{
            // call  api to add data
            const response = await execute('POST',`${protectedResources.api.baseEndPoint}/customerRoles/${dataItem.CustomerRoleId}/values`, newData);
            if(response.success){   
                message.success("Customer Role Value added successfully");
                newData = {...newData, ...response.data};
            }else{
                message.error(response.message);
                return;
            }
        }
        onDataEditorFinished(newData);
    }

    const handleDataEditCancelled = () => {
        console.log("handleDataEditCancelled");
        onDataEditorCancelled();
    }
    
    useEffect(() => {
        if(isOpen){
            form.setFieldsValue(dataItem);
        }
    }, [isOpen, dataItem, form]);

    return (
        <Modal
            open={isOpen}
            title={dataItem.Id ? "Edit Customer RoleValue" : "Add Customer RoleValue"}
            okText={<Space><SaveFilled />Save</Space> }
            cancelText={<Space><CloseSquareFilled />Cancel</Space> }
            okButtonProps={{ autoFocus: true, htmlType: 'submit' }} //form: 'dataEditorForm', key: 'submit'
            onCancel={handleDataEditCancelled}
            destroyOnClose
            modalRender={(dom) => (
                <Form
                  layout="horizontal"
                  form={form}
                  name="dataEditorForm"
                  labelCol={{ span: 8 }}
                  wrapperCol={{ span: 16 }}
                  initialValues={{
                    modifier: 'public',
                  }}
                  clearOnDestroy
                  onFinish={handleDataEditFinished}
                >
                  {dom}
                </Form>
            )}>            
            <Form.Item
                name="Id"
                label="Id"
                hidden>
                <Input />
            </Form.Item>
            <Form.Item
                name="Value"
                label="Value"
                rules={[{ required: true, message: 'Customer Role Value is required' }]}>
                <Input />
            </Form.Item>
            <Form.Item
                name="Status"
                label="Enable"
            >
                <Switch defaultChecked onChange={(checked)=>console.log(checked)} />

            </Form.Item>
        </Modal>
    );
};