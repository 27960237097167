import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import { useUser } from "contexts/UserContext";
import { FaBoxesStacked, FaFileInvoiceDollar, FaNetworkWired, FaUsers, FaWindows } from "react-icons/fa6";
import { useState } from "react";
import { Button, Menu } from "antd";
import {
  AppstoreOutlined,
  ContainerOutlined,
  DesktopOutlined,
  MailOutlined,
  MenuFoldOutlined,
  MenuUnfoldOutlined,
  NodeIndexOutlined,
  PieChartOutlined,
  SettingOutlined,
} from '@ant-design/icons';


const menuItems = [
    {
      key: "dashboard",
      label: "Dashboard",
      icon: <FaWindows />,
      activeIcon: <FaWindows />,
      isActive: true,
    },
    {
      key: "organization-management",
      label: "Organization Management",
      icon: <FaNetworkWired />,
      activeIcon: "https://cdn.builder.io/api/v1/image/assets/TEMP/e0d5114d4f088d39d7c00afca02d59b90b59b8249cd76e5b44ab05d4e86f0571?apiKey=1607f544a0344092815fcb145a04f0bb&",
    },
    {
      key: "module-management",
      label: "Module Management",
      icon: <FaBoxesStacked />,
      activeIcon: <FaBoxesStacked />,
    },
    {
      key: "manageCustomerRoles",
      label: "Customer Role Management",
      icon: <FaBoxesStacked />,
      activeIcon: <FaBoxesStacked />,
    },
    {
      key: "ETL_management",
      label: "ETL Management",
      icon: <FaBoxesStacked />,
      activeIcon: <FaBoxesStacked />,
    },
    {
      key: "subscriptions",
      label: "Subscriptions",
      icon: <FaFileInvoiceDollar />,
      activeIcon: <FaFileInvoiceDollar />,
    },
    {
      key: "manageUsers",
      label: "User Management",
      icon: <FaUsers />,
      activeIcon: <FaUsers />,
    },
];
  

const generalItems = [
    {
      key: 'general-group',
      label: 'General',
      type: 'group',
      children: [
      {
        key: "#1",
        label: "App Settings",
        icon: <SettingOutlined />,
        
        
      },
      {
        key: "#2",
        label: "Workspace",
        icon: <NodeIndexOutlined />,
        
      },
    ]
    },
    
];

const Sidebar = () => {
    const navigate = useNavigate();  
    const { userDetails } = useUser();

    const [collapsed, setCollapsed] = useState(false);
    const [items, setItems] = useState([...menuItems, ...generalItems]);


    const toggleCollapsed = () => {
      setCollapsed(!collapsed);
    };
    
    
    const handleMenuItemClick = (title) => {
        const specialRoutes = {
          "Dashboard":"/dashboard",

            "Organization Management": "/organization-management",
          "Module Management": "/module-management",
          "Subscriptions": "/subscriptions",
          "User Management": "/manageUsers",
          "Customer Role Management": "/manageCustomerRoles",
          "ETL Management": "/ETL_management",
          // Add more special titles and routes here as needed
        };
      
        // Check if the clicked title is a special title
        const specialRoute = specialRoutes[title];
      
        if (specialRoute) {
          navigate(specialRoute); // Navigate to the special route if it exists
        } else {
          navigate(""); // Default navigation logic for regular items
        }
    };

    const onMenuItemClicked = (e) => {
      //console.log('menu item clicked:', e);
      navigate("/" + e.key)
      
  };

    return (
      <>
        {/* <MobileButton type="primary" onClick={toggleCollapsed} style={{ marginBottom: 16,width:12 }}>
              {collapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
        </MobileButton> */}
        <SidebarWrapper>
          <SidebarHeader>
            <SidebarLogo src="https://cdn.builder.io/api/v1/image/assets/TEMP/1693daa69f399b0264e2363704c7f8661c830c9d2df73b95da0990e74078e70d?apiKey=1607f544a0344092815fcb145a04f0bb&" alt="Product Administration" />
            <SidebarTitle>Product Administration</SidebarTitle>
          </SidebarHeader>
          

          
          <Menu
            style={{
              backgroundColor: "#002C57",
              color: "white"
            }}
            defaultSelectedKeys={['1']}
            defaultOpenKeys={['sub1']}
            mode="inline"
            theme="dark"
            inlineCollapsed={collapsed}
            items={items}
            onClick={onMenuItemClicked}
          />

          
         
        </SidebarWrapper>
        </>
    );
};

const MobileButton = styled(Button)`
    display: none;
    margin-bottom: 16px;
    width: 12px;

    @media screen and (max-width: 768px) {
        display: inline-block;
    }
`;


const SidebarWrapper = styled.aside`
background-color: var(--bicollab-primary-color-blue-900, #002c57);
color: var(--bicollab-black-white-black-300, #bbbbbc);
display: flex;
flex-direction: column;
font-size: 14px;
font-weight: 500;
max-width: 424px;
padding: 16px 20px;

`;

const SidebarHeader = styled.header`
color: var(--bicollab-primary-color-blue-300, #4a9ecf);
display: flex;
font-size: 20px;
gap: 9px;
`;

const SidebarLogo = styled.img`
aspect-ratio: 1;
object-fit: auto;
object-position: center;
width: 53px;
`;

const SidebarTitle = styled.h1`
font-family: Ubuntu, sans-serif;
margin: auto 0;
overflow: hidden;
text-overflow: ellipsis;
white-space: nowrap;
font-size: 24px;
`;

const SidebarDivider = styled.img`
border: 1px solid var(--bicollab-black-white-black-300, #bbbbbc);
margin-top: 25px;
object-fit: auto;
object-position: center;
stroke-width: 1px;
width: 100%;
`;

const SidebarSection = styled.section`
display: flex;
flex-direction: column;
gap: 9px;
margin-top: 26px;
`;

const SidebarItem = styled.div`
align-items: center;
background-color: ${(props) => props.active ? "#FFFFFF" : "transparent"};  // White when active
border: 1px solid ${(props) => props.active ? "var(--bicollab-secondary-color-orange-700, #f16622)" : "transparent"};
border-radius: ${(props) => (props.active ? "2px" : "6px")};
color: ${(props) => props.active ? "var(--bicollab-secondary-color-orange-700, #f16622)" : "inherit"};
display: flex;
gap: 7px;
justify-content: space-between;
padding: 8px 0 8px 11px;
white-space: nowrap;
`;


const SidebarIcon = styled.img`
aspect-ratio: 1;
object-fit: auto;
object-position: center;
width: 24px;
`;

const SidebarItemTitle = styled.span`
flex: 1;
font-family: Ubuntu, sans-serif;
margin: auto 0;
color: var(--bicollab-black-white-black-300, #bbbbbc);
`;

const SidebarItemIcon = styled.img`
aspect-ratio: 1;
object-fit: auto;
object-position: center;
width: 24px;
`;

const SidebarSectionTitle = styled.div`
color: var(--bicollab-black-white-black-300, #bbbbbc);
display: flex;
font-size: 12px;
gap: 3px;
padding: 3px 1px;
white-space: nowrap;

span {
  flex-grow: 1;
  font-family: Ubuntu, sans-serif;
}
`;

const SidebarSectionDivider = styled.img`
border: 1px solid var(--bicollab-primary-color-blue-500, #007ab9);
margin: auto 0;
object-fit: auto;
object-position: center;
stroke-width: 1px;
width: 100%;
`;
export default Sidebar;