import React, { useEffect, useState } from 'react';
import OrgCustPageHeader from "./ETL_Header";
import OrgCustPageSearch from "./CustomerRoleFilter";
import OrgCustData from './ETL_Table';
import { useParams } from 'react-router-dom';
import { protectedResources } from 'config/msalConfig';
import execute from "hooks/execute";
import ETL_Header from './ETL_Header';
import ETL_Table from './ETL_Table';

function ETL_Manager({ userDetails }) {
    const { orgId } = useParams();
    const [searchResults, setSearchResults] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState('');

    const [organizationId, setOrganizationId] = useState(null)
    const [customerId, setCustomerId] = useState(null)
    const [moduleId, setModuleId] = useState(null)
    const [orgModules, setOrgModules] = useState(null)
    const [customerRoles, setCustomerRoles] = useState(null)

    const fetchCustomerRoles = async (values) => {
        console.log("fetching Roles")

        try {
            const response = await execute('GET', `${protectedResources.api.baseEndPoint}/customers/${values.customer_id}/modules/${values.module_id}/customerRoles`);
            if (response) {
                if (response.success) {
                    console.log(response.data)
                    setCustomerRoles(response.data)
                    console.log(orgModules)

                    // Function to find roles that exist in all modules
                    function getRolesInAllModules(data, moduleShortNames) {
                        const moduleGroups = new Map();

                        // Group roles by their name and track associated module short names
                        data.forEach(({ Name, shortName }) => {
                            if (!moduleGroups.has(Name)) {
                                moduleGroups.set(Name, new Set());
                            }
                            moduleGroups.get(Name).add(shortName);
                        });

                        // Filter roles that are present in all provided modules
                        const allModulesSet = new Set(moduleShortNames);
                        return Array.from(moduleGroups.entries())
                            .filter(([_, moduleSet]) => {
                                const moduleArray = Array.from(moduleSet);
                                return moduleArray.length >= allModulesSet.size && moduleArray.every(module => allModulesSet.has(module));
                            })
                            .map(([Name]) => Name); // Extract just the role names
                    }

                    // Example: Get roles present in all the given modules
                    // const rolesInAllModules = getRolesInAllModules(response.data, orgModules);

                    // console.log(rolesInAllModules);
                    // setOrganizationsWithModules(response.data)
                    // const result = response.data.map(item => ({
                    //     value: item.Org_ID.toString(),
                    //     label: item.Org_Name
                    // }));

                    // setOrganizations(result);
                    // setCustomers([])
                    // setModules([])
                    setLoading(false);
                    // setSearchResults(response.data.customers); // Assuming customers are part of the response
                } else {
                    setError(response.message || 'No organization found for the specified name');
                    setLoading(false);
                }
            }
        } catch (err) {
            setError('Failed to fetch data: ' + err.message);
            setLoading(false);
        }
    };

    const fetchOrganizationById = async (orgId) => {


        try {
            const response = await execute('GET', `${protectedResources.api.baseEndPoint}/organizations/${orgId}/modules`);
            if (response) {
                if (response.success) {
                    console.log(response.data)
                    setOrgModules(response.data)
                    // Get all unique ModuleIds



                    setLoading(false);
                    // setSearchResults(response.data.customers); // Assuming customers are part of the response
                } else {
                    setError(response.message || 'No organization found for the specified name');
                    setLoading(false);
                }
            }
        } catch (err) {
            setError('Failed to fetch data: ' + err.message);
            setLoading(false);
        }
    };

    const handleSearch = async (values) => {
        console.log(values)
        // fetchOrganizationById(values.organization_id)
        fetchCustomerRoles(values)
    }

    // useEffect(() => {
    //     const fetchData = async () => {
    //         try {
    //             const response = await execute('GET', `${protectedResources.api.baseEndPoint}/organizations/${orgId}`);
    //             if(response){
    //                 if (response.success) {  
    //                     setOrganization(response.data);
    //                     setLoading(false);
    //                     // setSearchResults(response.data.customers); // Assuming customers are part of the response
    //                 }else{
    //                     setError(response.message || 'No organization found for the specified name');
    //                     setLoading(false);
    //                 }
    //             }
    //         } catch (err) {
    //             setError('Failed to fetch data: ' + err.message);
    //             setLoading(false);
    //         }
    //     };
    //     if(orgId > 0){        
    //         fetchData();
    //     }
    // }, [orgId, execute]);





    return (

        <div
        style={{
            width: "90%",
            marginLeft: "auto",
            marginRight: "auto",
            padding: "20px 0",
          }}
        >
            <ETL_Header />
            {/* <CustomerRoleFilter setSearchResults={setSearchResults} organizationId={organizationId} setOrganizationId={setOrganizationId} customerId={customerId} setCustomerId={setCustomerId} moduleId={moduleId} setModuleId={setModuleId} handleSearch={handleSearch} /> */}
            <ETL_Table searchResults={searchResults} customerRoles={customerRoles} customerId={customerId} organizationId={organizationId} moduleId={moduleId} fetchCustomerRoles={fetchCustomerRoles} />
        </div>

    );
}

export default ETL_Manager;
