import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import execute from "hooks/execute";
import { protectedResources } from 'config/msalConfig';
import NewModuleModal from './NewModuleModal';



function PageHeader({ setSearchResults }) {
    const [modalIsOpen, setModalIsOpen] = useState(false);
    const [modules, setModules] = useState([]);

    const toggleModal = () => {
        setModalIsOpen(!modalIsOpen);
    };

    const fetchData = async () => {
        try {
            var response = await execute("GET", `${protectedResources.api.baseEndPoint}/modules`);            
            if(response){
                if(response.success){
                    setModules(response.data);
                    setSearchResults(response.data);
                }else{
                    throw Error('Failed to fetch data: ' + response.message);
                }                
            }            
        } catch (err) {
            console.error('Failed to fetch data: ' + err.message);
        }
    };

    useEffect(() => {
        fetchData();
    }, []);

    return (
        <OutsideContainer>
            <TitleContainer>
                <Title>Module Management</Title>
            </TitleContainer>
        </OutsideContainer>
    );
}





const OutsideContainer = styled.div`
    padding: 10px;
    border-radius: 0px;
    margin: 20px;
    width: 100%;
    margin-left: 0;
    padding-bottom: 5px;
`;

const TitleContainer = styled.div`
    position: sticky;
    top: 0;
    z-index: 1;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 20px;

    margin-right: -10px;
`;

const Title = styled.h1`
    margin: 0;
    font-size: 30px;
    font-family: Ubuntu, sans-serif;
    color: var(--bicollab-primary-color-blue-900, #002c57);
`;

const AddButton = styled.button`
    padding: 10px 18px;
    font-size: 20px;
    font-family: Ubuntu, sans-serif;
    color: var(--bicollab-black-white-white, #fff);
    background-color: var(--bicollab-secondary-color-orange-700, #f16622);
    border: none;
    border-radius: 4px;
    cursor: pointer;
`;

export default PageHeader;