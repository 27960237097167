import React, { useState } from 'react';
import styled from 'styled-components';
import execute from "hooks/execute";
import { protectedResources } from 'config/msalConfig';



function UserPageSearch({ setSearchResults }) {
    const [organization, setOrganization] = useState('');
    const [domain, setDomain] = useState('');

    const handleSearch = () => {
      execute('GET', `${protectedResources.api.baseEndPoint}/adminUsers?${new URLSearchParams({
        email: organization,
        isActive: domain,
      })}`)
        .then(response => {
          setSearchResults(response.data);
          console.log('Search Results:', response.data);
        })
        .catch(error => {
          console.error('Error fetching data:', error);
        });
    };
  
    const handleOrganizationChange = (e) => {
      setOrganization(e.target.value);
    };
  
    const handleDomainChange = (e) => {
      setDomain(e);
    };
  
    return (
      <SearchParameterContainer>
        <SearchParameterTitle>Search Parameter</SearchParameterTitle>
        <SearchParameterFields>
          <InputField
            label="User Name"
            placeholder="Enter a name"
            value={organization}
            onChange={handleOrganizationChange}
          />
          <SelectField
            label="Status"
            options={[
              { value: 'active', label: 'Active' },
              { value: 'inactive', label: 'Inactive' }
            ]}
            placeholder="Select Status"
            onChange={handleDomainChange}
          />
          <SearchButton onClick={handleSearch}>Search</SearchButton>
        </SearchParameterFields>
      </SearchParameterContainer>
    );
  }

  function SelectField({ label, options, placeholder, onChange }) {
    const handleSelectChange = (event) => {
      onChange(event.target.value);
    };
  
    return (
      <SelectFieldContainer>
        <SelectFieldLabel>{label}</SelectFieldLabel>
        <SelectWrapper>
          <select onChange={handleSelectChange}>
            <option value="">{placeholder}</option>
            {options.map(option => (
              <option key={option.value} value={option.value}>
                {option.label}
              </option>
            ))}
          </select>
        </SelectWrapper>
      </SelectFieldContainer>
    );
  }
  
  function InputField({ label, placeholder, value, onChange }) {
    return (
      <InputFieldContainer>
        <InputFieldLabel>{label}</InputFieldLabel>
        <InputFieldWrapper
          type="text"  // Specify the type if necessary, or make it a prop if you need different types
          placeholder={placeholder}
          value={value}  // Bind the input value to the value prop
          onChange={onChange}  // Propagate the onChange event to the parent component
        />
      </InputFieldContainer>
    );
  }
  
  const SearchParameterContainer = styled.section`
    display: flex;
    flex-direction: column;
    align-items: stretch; // Ensures that child components stretch to fill the container width
    border: 1px solid #BBBBBC;
    background-color: #F2F2F2;
    padding: 20px;
    margin: 20px 0; // Adds vertical spacing around the container;
    width: 126%;
    height: 25%;
    margin-left:-150px;
    margin-top:-10px;
  `;
  
  const SearchParameterTitle = styled.h2`
    color: var(--bicollab-primary-color-blue-900, #002c57);
    letter-spacing: 0.4px;
    font: 500 16px/100% Ubuntu, sans-serif;
  
    @media (max-width: 991px) {
      max-width: 100%;
    }
  `;
  
  const SearchParameterFields = styled.div`
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center; // Make sure all elements are vertically aligned
    gap: 10px;
    margin-top: 24px;
  `;

  


const SelectWrapper = styled.div`
position: relative;
display: flex;
align-items: center;
background-color: #FFFFFF;
border: 1px solid #BBBBBC;
border-radius: 2px;
padding: 10px 15px;  // Increased padding to add more space inside the box
width: 90%;  // Ensures the select fills the container

select {
  border: none;
  width: 100%;
  padding-right: 30px;  // Make room for the icon, adjust this if necessary
  -moz-appearance: none;  // Remove default style Mozilla
  -webkit-appearance: none;  // Remove default style Chrome and Safari
  appearance: none;  // Remove default style
  background: none;
  font-family: Ubuntu, sans-serif;
  font-size: 16px;
  color: #767676;
}

&:after {
  content: '▼';  // Adds a custom dropdown icon
  position: absolute;
  right: 15px;  // Adjusted to match the increased padding
  top: 50%;
  transform: translateY(-50%);
  color: #767676;
  pointer-events: none;  // Makes sure clicks on the icon interact with the select
}
`;

const SelectFieldLabel = styled.label`
color: #767676;
text-align: left;
font: 500 14px Ubuntu, sans-serif;
padding-bottom: 10px;
`;

const SelectFieldContainer = styled.div`
flex: 1; // Takes an equal portion of the available space
display: flex;
flex-direction: column;
padding: 0 5px; // Give some space around the fields
margin-top: 8px;
`;

const InputFieldContainer = styled.div`
flex: 1; // Equal flex distribution
display: flex;
flex-direction: column;
padding: 0 5px; // Consistent padding with SelectField
`;

const SearchButton = styled.button`
border-radius: 4px;
background-color: var(--bicollab-secondary-color-orange-700, #f16622);
align-self: flex-end;
margin-top: 28px;
padding: 10px 18px;
color: var(--bicollab-black-white-white, #fff);
white-space: nowrap;
font: 500 20px Ubuntu, sans-serif;

@media (max-width: 991px) {
  white-space: initial;
  padding: 0 20px;
}
`;

const InputFieldLabel = styled.label`
  color: #767676;
  text-align: left;
  font: 500 14px Ubuntu, sans-serif;
`;

const InputFieldWrapper = styled.input`
  border-radius: 2px;
  border: 1px solid rgba(187, 187, 188, 1);
  background-color: var(--bicollab-black-white-white, #fff);
  margin-top: 13px;
  padding: 10px 21px;
  color: var(--bicollab-black-white-black-300, #bbbbbc);
  font: 400 16px Ubuntu, sans-serif;

  @media (max-width: 991px) {
    padding: 0 20px;
  }
`;

export default UserPageSearch;