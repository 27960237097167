

import React from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import OrgManager from 'views/OrgManager';
import ModuleManager from 'views/ModuleManager';
import OrgCustomersManager from 'views/OrgCustomersManager';
import Subscriptions from 'views/Subscriptions';
import { UserProvider } from './contexts/UserContext';
import UserManager from 'views/UserManager';
import PrivateRoute from './components/layout/ProtectedRoute';
import { useEffect } from 'react';
import { MsalProvider, useMsal } from '@azure/msal-react';
import { EventType } from '@azure/msal-browser';

import { PageLayout } from './components/layout/PageLayout';
import { b2cPolicies, protectedResources } from './config/msalConfig';
import { compareIssuingPolicy } from './utils/claimUtils';
import { ConfigProvider } from 'antd';
import Dashboard from 'views/Dashboard';
import CustomerRoleManager from 'views/CustomerRoleManager';
import ETL_Manager from 'views/ETL_Manager';

const Pages = () => {

  /**
   * useMsal is hook that returns the PublicClientApplication instance,
   * an array of all accounts currently signed in and an inProgress value
   * that tells you what msal is currently doing. For more, visit:
   * https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-react/docs/hooks.md
   */
  const { instance } = useMsal();
  useEffect(() => {
    const callbackId = instance.addEventCallback((event) => {
        if (
            (event.eventType === EventType.LOGIN_SUCCESS || event.eventType === EventType.ACQUIRE_TOKEN_SUCCESS) &&
            event.payload.account
        ) {
            /**
             * For the purpose of setting an active account for UI update, we want to consider only the auth
             * response resulting from SUSI flow. "tfp" claim in the id token tells us the policy (NOTE: legacy
             * policies may use "acr" instead of "tfp"). To learn more about B2C tokens, visit:
             * https://docs.microsoft.com/en-us/azure/active-directory-b2c/tokens-overview
             */
            if (compareIssuingPolicy(event.payload.idTokenClaims, b2cPolicies.names.editProfile)) {
                // retrieve the account from initial sing-in to the app
                const originalSignInAccount = instance
                    .getAllAccounts()
                    .find(
                        (account) =>
                            account.idTokenClaims.oid === event.payload.idTokenClaims.oid &&
                            account.idTokenClaims.sub === event.payload.idTokenClaims.sub && 
                            compareIssuingPolicy(account.idTokenClaims, b2cPolicies.names.signIn)        
                    );

                let signInFlowRequest = {
                    authority: b2cPolicies.authorities.signIn.authority,
                    account: originalSignInAccount,
                };

                // silently login again with the signIn policy
                instance.ssoSilent(signInFlowRequest);
            }

            /**
             * Below we are checking if the user is returning from the reset password flow.
             * If so, we will ask the user to reauthenticate with their new password.
             * If you do not want this behavior and prefer your users to stay signed in instead,
             * you can replace the code below with the same pattern used for handling the return from
             * profile edit flow
             */
            if (compareIssuingPolicy(event.payload.idTokenClaims, b2cPolicies.names.forgotPassword)) {
                let signInFlowRequest = {
                    authority: b2cPolicies.authorities.signIn.authority,
                    scopes: [
                        ...protectedResources.apiTodoList.scopes.read,
                        ...protectedResources.apiTodoList.scopes.write,
                    ],
                };
                instance.loginRedirect(signInFlowRequest);
            }
        }

        if (event.eventType === EventType.LOGIN_FAILURE) {
            // Check for forgot password error
            // Learn more about AAD error codes at https://docs.microsoft.com/en-us/azure/active-directory/develop/reference-aadsts-error-codes
            if (event.error && event.error.errorMessage.includes('AADB2C90118')) {
                const resetPasswordRequest = {
                    authority: b2cPolicies.authorities.forgotPassword.authority,
                    scopes: [],
                };
                instance.loginRedirect(resetPasswordRequest);
            }
        }
    });

    return () => {
        if (callbackId) {
            instance.removeEventCallback(callbackId);
        }
    };
    // eslint-disable-next-line
  }, [instance]);



  return (
      <Routes>
        <Route path="/" element={<PrivateRoute element={Dashboard} />} />
        <Route path="/redirect" element={<Navigate to="/" />} />
        <Route path="/organization-management" element={<PrivateRoute element={OrgManager} />} />
        <Route path="/module-management" element={<PrivateRoute element={ModuleManager} />} />
        <Route path="/dashboard" element={<PrivateRoute element={Dashboard} />} />
        <Route path="/org-customers/:orgId" element={<PrivateRoute element={OrgCustomersManager} />} />
        <Route path="/subscriptions" element={<PrivateRoute element={Subscriptions} />} />
        <Route path="/manageUsers" element={<PrivateRoute element={UserManager} />} />
        <Route path="/manageCustomerRoles" element={<PrivateRoute element={CustomerRoleManager} />} />
        <Route path="/ETL_management" element={<PrivateRoute element={ETL_Manager} />} />
      </Routes>
  );
};

/**
* msal-react is built on the React context API and all parts of your app that require authentication must be
* wrapped in the MsalProvider component. You will first need to initialize an instance of PublicClientApplication
* then pass this to MsalProvider as a prop. All components underneath MsalProvider will have access to the
* PublicClientApplication instance via context as well as all hooks and components provided by msal-react. For more, visit:
* https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-react/docs/getting-started.md
*/
const App = ({ instance }) => {
  return (
    <MsalProvider instance={instance}>
        <ConfigProvider     
            theme={{
                token: {
                    // Seed Token
                    colorPrimary: '#F16622',
                    borderRadius: 2,

                    // Alias Token
                    abcolorBgContainer: '#007ab9',
                },
                message: {
                    top: 200,
                }
            }}>
            <UserProvider>
                <PageLayout>
                    <Pages />
                </PageLayout>      
            </UserProvider>              
        </ConfigProvider>

    </MsalProvider>
);
};

export default App;
