import React from 'react';
import DashboardCards from './DashboardCards';
import LastActionTaken from './LastActionTaken';

function Dashboard({ userDetails }) {
    
  return (
    <div
    style={{
      width: "90%",
      margin: "auto",
      padding: "20px 0",
    }}
    >
          <LastActionTaken />
          <DashboardCards />
    </div>
  );
};

export default Dashboard;


