import { App, DatePicker, Form, Input, InputNumber, Modal, Space } from "antd";
import { CloseSquareFilled, SaveFilled } from '@ant-design/icons';
import { useEffect } from "react";
import execute from "hooks/execute";
import { protectedResources } from "config/msalConfig";
import { get } from "lodash";

export const DataEditor = ({isOpen, dataItem, onDataEditorFinished, onDataEditorCancelled }) => {
    const [form] = Form.useForm();
    const { message } = App.useApp();

    const handleDataEditFinished = async (values) => {
        console.log("handleDataEditFinished");
        var newData = {...dataItem, ...values};
        console.log(newData);
        if(newData.Customer_ID){
            // call api to update data
            const response = await execute('PUT',`${protectedResources.api.baseEndPoint}/customers`, newData );
            if(response.success){
                message.success("Customer updated successfully");
                newData = {...newData, ...response.data};
            }else{
                message.error(response.message);
                return;
            }
        }
        else{
            // call  api to add data
            const response = await execute('POST',`${protectedResources.api.baseEndPoint}/customers`, newData);
            if(response.success){   
                message.success("Customer added successfully");
                newData = {...newData, ...response.data};
            }else{
                message.error(response.message);
                return;
            }
        }
        onDataEditorFinished(newData);
    }

    const handleDataEditCancelled = () => {
        console.log("handleDataEditCancelled");
        onDataEditorCancelled();
    }
    
    useEffect(() => {
        if(isOpen){
            form.setFieldsValue(dataItem);
        }
    }, [isOpen, dataItem, form]);

    return (
        <Modal
            open={isOpen}
            title="Customer Editor"
            okText={<Space><SaveFilled />Save</Space> }
            cancelText={<Space><CloseSquareFilled />Cancel</Space> }
            okButtonProps={{ autoFocus: true, htmlType: 'submit' }} //form: 'dataEditorForm', key: 'submit'
            onCancel={handleDataEditCancelled}
            destroyOnClose
            modalRender={(dom) => (
                <Form
                  layout="horizontal"
                  form={form}
                  name="dataEditorForm"
                  labelCol={{ span: 8 }}
                  wrapperCol={{ span: 16 }}
                  initialValues={{
                    modifier: 'public',
                  }}
                  clearOnDestroy
                  onFinish={handleDataEditFinished}
                >
                  {dom}
                </Form>
            )}>            
            <Form.Item
                name="Customer_ID"
                label="Customer ID"
                hidden>
                <Input />
            </Form.Item>


            <Form.Item
                name="CustomerName"
                label="Customer Name"
                rules={[{ required: true, message: 'Customer Name is required' }]}>
                <Input />
            </Form.Item>
            <Form.Item
                name="Domain"
                label="Domain"
                rules={[
                    { required: true, message: 'Domain is required' },
                    { pattern: /^[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/, message: 'Invalid domain name' }]}>
                <Input
                disabled={dataItem.Customer_ID ? true : false}  />
            </Form.Item>
            <Form.Item
                name="Address"
                label="Address"
                rules={[{ required: true, message: 'Address is required' }]}>
                <Input />
            </Form.Item>
            <Form.Item
                name="Workspace_Name"
                label="Workspace Name"
                rules={[{ required: true, message: 'Workspace Name is required' }]}>
                <Input
                disabled={dataItem.Customer_ID ? true : false}  />
            </Form.Item>
            <Form.Item
                name="DatabaseName"
                label="Database"
                rules={[{ required: true, message: 'Database Name is required' }]}>
                <Input
                disabled={dataItem.Customer_ID ? true : false}  />
            </Form.Item>
            <Form.Item
                name="CustEmail"
                label="Customer Email"
                dependencies={['Domain']}
                hasFeedback
                rules={[
                    { required: true, message: 'Customer Email is required' },
                    {type: 'email', message: 'Invalid email address'},
                    ({ getFieldValue }) => ({
                        validator(rule, value) {
                            if (!value || getFieldValue('Domain') === value.split('@')[1]) {
                                return Promise.resolve();
                            }
                            return Promise.reject('Email domain must match the domain');
                        },
                    }),
                    // validate from server if email is not already taken
                    ({ getFieldValue }) => ({
                        validator: async (_, value) => {
                            if (value) {
                                if(getFieldValue('Customer_ID')){
                                    return Promise.resolve();
                                }
                                try {
                                    const response = await execute('GET', `${protectedResources.api.baseEndPoint}/customerUsers/exists/${value}`);
                                    if (response.success && response.data.exists === false) {
                                        return Promise.resolve();
                                    }
                                    return Promise.reject(response.message || 'Failed to check if user exists');
                                } catch (error) {
                                    console.error('Error checking if user exists:', error);
                                    return Promise.reject('Error checking if user exists: ' + error.message);
                                }
                            }
                        },
                    }),
                    ]}>
                <Input 
                disabled={dataItem.Customer_ID ? true : false} />
            </Form.Item>
        </Modal>
    );
};