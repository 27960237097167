import { App, Checkbox, DatePicker, Form, Input, InputNumber, Modal, Space } from "antd";
import { CloseSquareFilled, SaveFilled } from '@ant-design/icons';
import { useEffect, useState } from "react";
import execute from "hooks/execute";
import { protectedResources } from "config/msalConfig";
import { get } from "lodash";
import styled from "styled-components";

export const DataEditor = ({isOpen, dataItem, onDataEditorFinished, onDataEditorCancelled }) => {
    const [form] = Form.useForm();
    const { message } = App.useApp();

    const [modules, setModules] = useState([]);
    const [modulesAssigned, setModulesAssigned] = useState({});
    const [loading, setLoading] = useState(false);


    const fetchModules = async () => {
            setLoading(true)
            try {
      
              execute("GET", `${protectedResources.api.baseEndPoint}/modules`).then((response) => {
                if (response && response.success) {
                  console.log('response', response);
                  setModules(response.data); // Adjust the response according to your API
                  // Initialize modulesAssigned state
                  const initialAssignedState = response.data.reduce((acc, module) => {
                    acc[module.ShortName] = false;
                    return acc;
                  }, {});
                  setModulesAssigned(initialAssignedState);
                }
              });
      
      
            } catch (error) {
              console.error('Error fetching modules:', error);
            }

            setLoading(false)
    };
    

    const handleDataEditFinished = async (values) => {
        console.log("handleDataEditFinished");
        var newData = {...dataItem, ...values};
        console.log(newData);
        const sampleData = {
            "organizationName": "Soluta duis consequa",
            "address": "Illum ex quis iure ",
            "adminEmail": "pacamo@mailinator.com",
            "modulesAssigned": {
                "CM": false,
                "FIN": true,
                "HS": false,
                "IM": true,
                "SAL": true,
                "HR": true,
                "UTIL": false,
                "PROD": false,
                "TC": true,
                "QO": true,
                "CEN": false
            }
        }

        const modulesAssigned = {
            CEN : newData.CEN || false,
            CM : newData.CM || false,
            FIN : newData.FIN || false,
            HR : newData.HR || false,
            HS : newData.HS || false,
            IM : newData.IM || false,
            PROD : newData.PROD || false,
            QO : newData.QO || false,
            SAL : newData.SAL || false,
            TC : newData.TC || false,
            UTIL : newData.UTIL || false,
        }

        console.log(modulesAssigned)

        newData.modulesAssigned = modulesAssigned
        console.log(newData)

        // return

        if(dataItem){
            console.log("edit")
            const editsample = {
                "Org_ID": 83,
                "Org_Name": "Hyde Johns Traders",
                "Org_Address": "Voluptate rerum fugi",
                "Org_Email": "mufakifam@mailinator.com",
                "CreatedOn": "2024-12-26T07:03:29.563Z",
                "Modules": "CEN, CM, FIN, HS, IM, PROD, SAL, TC, UTIL",
                "modifiedFields": [],
                "activeModules": {
                    "CM": true,
                    "FIN": true,
                    "HS": true,
                    "IM": true,
                    "SAL": true,
                    "HR": true,
                    "UTIL": true,
                    "PROD": true,
                    "TC": true,
                    "CEN": true
                }
            }
            // return;
            // call api to update data
            const response = await execute('PUT',`${protectedResources.api.baseEndPoint}/organizations`, newData );
            if(response.success){
                message.success("Organization updated successfully");
                newData = {...newData, ...response.data};
            }else{
                message.error(response.message);
                return;
            }
        }
        else{
            console.log("add")
            // call  api to add data
            const response = await execute('POST',`${protectedResources.api.baseEndPoint}/organizations`, newData);
            if(response.success){   
                message.success("Organization added successfully");
                newData = {...newData, ...response.data};
            }else{
                message.error(response.message);
                return;
            }
        }
        onDataEditorFinished(newData);
    }

    const handleDataEditCancelled = () => {
        console.log("handleDataEditCancelled");
        onDataEditorCancelled();
    }
    
    useEffect(() => {
        // Convert Modules string to an array
        console.log(dataItem)
        if(isOpen){
            if(dataItem){

                form.setFieldsValue({
                    organizationName: dataItem?.Org_Name || '',
                    address: dataItem?.Org_Address || '',
                    adminEmail: dataItem?.Org_Email || '',
                    // adminEmail: dataItem.Org_Email || '',
                });
            }
            
        }
    }, [isOpen, dataItem, form]);

    useEffect(()=>{
        if(dataItem){
            console.log("dataItem/////////")
            console.log(dataItem)
            if (typeof dataItem.Modules === "string") {
                dataItem.Modules = dataItem.Modules.split(", ").map(module => module.trim());
            }

            const moduleValues = dataItem.Modules.reduce((acc, module) => {
                acc[module] = true;
                return acc;
            }, {});

            form.setFieldsValue({
                ...moduleValues
            });
        }
    },[])

    useEffect(()=>{
        fetchModules()
    },[])



    return (
        <Modal
            open={isOpen}
            className="h-3"
            title={dataItem ? "Edit Organization" : "Add New Organization"}
            okText={<Space><SaveFilled />Save</Space> }
            cancelText={<Space><CloseSquareFilled />Cancel</Space> }
            okButtonProps={{ autoFocus: true, htmlType: 'submit' }} //form: 'dataEditorForm', key: 'submit'
            onCancel={handleDataEditCancelled}
            destroyOnClose
            modalRender={(dom) => (
                <Form
                style={{height: "80vh", overflowY: "auto"}}
                  layout="horizontal"
                  form={form}
                  name="dataEditorForm"
                  labelCol={{ span: 8 }}
                  wrapperCol={{ span: 16 }}
                  initialValues={{
                    modifier: 'public',
                  }}
                  clearOnDestroy
                  onFinish={handleDataEditFinished}
                >
                  {dom}
                </Form>
            )}>            
            <Form.Item
                name="organizationName"
                label="Organization Name"
                rules={[{ required: true, message: 'Organization Name is required' }]}>
                <Input />
            </Form.Item>


            <Form.Item
                name="address"
                label="Address"
                rules={[{ required: true, message: 'Address is required' }]}>
                <Input />
            </Form.Item>
            <Form.Item
                name="adminEmail"
                label="Admin User Email"
                rules={[
                    { required: true, message: 'Admin User Email is required' },
                ]}>
                <Input readOnly={dataItem}/>
            </Form.Item>

            <Form.Item
                label="Modules To Assign"
                name="modulesAssigned"
            >
                <div style={{ display: 'grid', gridTemplateColumns: '1fr 1fr', gap: '0px' }}>
            
                {modules.map((module) => (
                     <Form.Item
                     style={{marginBottom: 8, height: "fit-content"}}
                     name={module.ShortName}
                     valuePropName="checked"
                 >
                    <Checkbox key={module.ShortName}>{module.ModuleName}</Checkbox>
                  </Form.Item>
                ))}
                </div>
            </Form.Item>
            
        </Modal>
    );
};


const FormLabel = styled.label`
display: block;  // Ensures the label is a block element
margin-bottom: 8px;  // Increased space between the label text and the input field
color: #333;  // Optional: text color for the label
`;