import { Table } from "antd";
import { protectedResources } from "config/msalConfig";
import execute from "hooks/execute";
import { useEffect, useState } from "react";
import CustomerRoleData from "../CustomerRoles/CustomerRoleData";


const OrgModuleData = ({customer}) => {
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState('');
    const [data, setData] = useState([]);

    const columns = [
        {
            title: 'Module Name',
            dataIndex: 'ModuleName',
            key: 'ModuleName',
            sorter: (a, b) => a.ModuleName.toString().localeCompare(b.ModuleName.toString())
        }];



    useEffect(() => {
        const fetchData = async () => {
            try {
                setLoading(true);
                const response = await execute('GET', `${protectedResources.api.baseEndPoint}/organizations/${customer.Org_ID}/modules`);
                console.log('response', response);
                if (response.success && response.data.length > 0) {
                    var data = response.data;
                    data.sort((a, b) => a.Order > b.Order);
                    setData(data);
                    setError('');
                } else {
                    setData([]);
                    setError(response.message || 'No customers found for the specified organization');
                }
                setLoading(false);
            } catch (err) {
                setError('Failed to fetch data: ' + err.message);
                setLoading(false);
            }
        };
        console.log(customer);
        if(customer){
            fetchData();
        }
    }, [customer]);

    const expandedRowRender = (record) => (
        <CustomerRoleData customer={customer} moduleId={record.Id} />
    );

    return (
        <Table 
            rowKey={record => record.Id} 
            columns={columns} 
            dataSource={data}   
            loading={loading}              
            expandable={{
                expandedRowRender,
                defaultExpandedRowKeys: ['0'],
            }} 
            pagination={false} />
    );

}

export default OrgModuleData;