import * as React from "react";
import styled from "styled-components";
import { useMsal } from '@azure/msal-react';
import { useNavigate } from 'react-router-dom';
import { Dropdown, Space } from "antd";
import { DownOutlined } from "@ant-design/icons";


const UsersDropDownMenuItems = [
  {
    label: "View Profile",
    key: '1',
  },
  {
    label: "Log Out",
    key: '2',
  }
];

function Header() {
    const [isDropdownOpen, setIsDropdownOpen] = React.useState(false);
    const { instance } = useMsal();
    const navigate = useNavigate();
    let activeAccount;

    if (instance) {
        activeAccount = instance.getActiveAccount();
    }

    const handleDropdownClick = () => {
        setIsDropdownOpen(prevState => !prevState);
    };

    const userMenuItemClicked = (e) => {
      
      if(e.key === '1'){
        navigate('/profile');
      }else if(e.key === '2'){
        handleLogout();
      }
  }

    const handleLogout = async () => {
        console.log("Logging out...");
        localStorage.clear();
        sessionStorage.clear();
        

        await instance.logoutRedirect({
            postLogoutRedirectUri: window.location.origin
        });

        console.log("Logged out, navigating to home...");
        navigate('/');
    };

    return (
        <HeaderWrapper>
            <HeaderContent>
                <CompanyInfo>
                    <CompanyLogo src="https://cdn.builder.io/api/v1/image/assets/TEMP/7409bf763a6e548815c3ed3a78c70dc50622c59a9454d116f24b7a8bf86d24c0?apiKey=1607f544a0344092815fcb145a04f0bb&" alt="Company Logo" />
                    <CompanyName src="https://cdn.builder.io/api/v1/image/assets/TEMP/01c64fc43b7129060e8cd5f8f49f591569945c9284eb684fb22c875d0d05e219?apiKey=1607f544a0344092815fcb145a04f0bb&" alt="Company Name" />
                </CompanyInfo>
                <UserInfo>
                    <NotificationIcon src="https://cdn.builder.io/api/v1/image/assets/TEMP/d847bd6dfb10b8894580f3d116255e05322968ac9903c8b628bb5332606c7c37?apiKey=1607f544a0344092815fcb145a04f0bb&" alt="Notification Icon" />
                    <SettingsIcon src="https://cdn.builder.io/api/v1/image/assets/TEMP/9175f8a107ddab38d030742e24db309376627abf0d0cac4e10bea62b7ea56b1f?apiKey=1607f544a0344092815fcb145a04f0bb&" alt="Settings Icon" />
                    <UserDetails>
                        
                        <Dropdown menu={{
                          items: UsersDropDownMenuItems,
                          onClick: userMenuItemClicked
                        }}>
                          <a onClick={(e)=> e.preventDefault()}>
                            <Space>
                              <UserAvatar src="https://cdn.builder.io/api/v1/image/assets/TEMP/da4bfac15f671a5727c24c3f5a541d027b8b9af202a0c3c77bd85d41e9b7e440?apiKey=1607f544a0344092815fcb145a04f0bb&" alt="User Avatar" />
                              {activeAccount ? activeAccount.name : "Loading..."}                          
                              <DownOutlined style={{color: '#f16622'}} />
                            </Space>
                          </a>
                    </Dropdown>
                    </UserDetails>
                </UserInfo>
            </HeaderContent>
        </HeaderWrapper>
    );
}

const HeaderWrapper = styled.header`
  box-shadow: 0px 2px 20px 0px rgba(0, 0, 0, 0.15);
  background-color: var(--bicollab-black-white-white, #fff);
  display: flex;
  gap: 20px;
  justify-content: space-between;
  padding: 19px 21px;

  @media (max-width: 991px) {
    flex-wrap: wrap;
    padding: 0 20px;
  }
`;

const HeaderContent = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`;

const CompanyInfo = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
`;

const CompanyLogo = styled.img`
  width: 40px;
  aspect-ratio: 0.95;
  object-fit: contain;
`;

const CompanyName = styled.img`
  width: 248px;
  aspect-ratio: 10;
  object-fit: contain;
`;

const UserInfo = styled.div`
  display: flex;
  align-items: center;
  gap: 20px;
  font-size: 14px;
  color: var(--bicollab-black-white-black-500, #515151);
  font-weight: 500;
  text-align: center;
`;

const NotificationIcon = styled.img`
  width: 14px;
  aspect-ratio: 0.88;
  object-fit: contain;
  fill: var(--bicollab-secondary-color-orange-700, #f16622);
`;

const SettingsIcon = styled.img`
  width: 24px;
  aspect-ratio: 1;
  object-fit: contain;
`;

const UserDetails = styled.div`
  display: flex;
  align-items: center;
  gap: 3px;
`;

const UserAvatar = styled.img`
  width: 24px;
  aspect-ratio: 1;
  object-fit: contain;
`;

const UserName = styled.span`
  font-family: Ubuntu, sans-serif;
`;

const DropdownIcon = styled.img`
  width: 22px;
  aspect-ratio: 1;
  object-fit: contain;
  align-self: start;
`;

const DropdownMenu = styled.div`
  position: absolute;
  top: 50px;
  right: 0;
  background-color: white;
  border: 1px solid #ccc;
  border-radius: 4px;
  padding: 8px;
  z-index: 1;
  width: 190px;
  height: 100px;
  display: ${({ isOpen }) => (isOpen ? 'block' : 'none')};
`;

const DropdownOption = styled.div`
  padding: 10px;
  cursor: pointer;
  &:hover {
    background-color: #f0f0f0;
  }
`;

export default Header;
