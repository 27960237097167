
import styled from 'styled-components';

const PageHeader = styled.header`
display: flex;
width: 100%;
justify-content: space-between;
align-items: center;
gap: 10px; // Maintain a reasonable gap between inner elements
padding: 20px 0px; // Adjusted padding for the header
margin-right: -100px; // Ensure this is actually needed, otherwise remove
@media (max-width: 991px) {
  flex-wrap: wrap;
}
`;

export default PageHeader;