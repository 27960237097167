
import styled from "styled-components";

function LastActionTaken() {
    return (
      <LastActionTakenContainer>
        <LastActionTakenTitle>Last action taken</LastActionTakenTitle>
        <LastActionTakenContent>
          <LastActionTakenIcon src="https://cdn.builder.io/api/v1/image/assets/TEMP/9e5cbe2a5076c93e9f5e272cd87bff1819dd54daa4f3af93a6c2073a0766d103?apiKey=1607f544a0344092815fcb145a04f0bb&" alt="Last action taken icon" />
          <LastActionTakenText>
            Organization Management : Edited at 2:30 PM, 25 Apr, 2024
          </LastActionTakenText>
        </LastActionTakenContent>
      </LastActionTakenContainer>
    );
  }
  

  const LastActionTakenContainer = styled.div`
    display: flex;
    flex-direction: column;
    font-size: 14px;
  `;
  
  const LastActionTakenTitle = styled.h2`
    color: var(--bicollab-primary-color-blue-900, #002c57);
    font-family: Ubuntu, sans-serif;
    font-weight: 500;
    width: 100%;
    
    @media (max-width: 991px) {
      max-width: 100%;
    }
  `;
  
  const LastActionTakenContent = styled.div`
    border-radius: 2px;
    background-color: rgba(255, 205, 3, 0.15);
    display: flex;
    margin-top: 20px;
    gap: 10px;
    color: var(--bicollab-black-white-black-700, #1c1c1c);
    font-weight: 400;
    padding: 20px 10px;
    
    @media (max-width: 991px) {
      flex-wrap: wrap;
    }
  `;
  
  const LastActionTakenIcon = styled.img`
    aspect-ratio: 1;
    object-fit: contain;
    width: 25px;
  `;
  
  const LastActionTakenText = styled.p`
    font-family: Ubuntu, sans-serif;
    margin: auto 0;
    
    @media (max-width: 991px) {
      max-width: 100%;
    }
  `;

  export default LastActionTaken;