import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import execute from "hooks/execute";
import { protectedResources } from 'config/msalConfig';

function formatDate(dateString) {
    const date = new Date(dateString);
    return date.toLocaleDateString('en-US');  // Adjust 'en-US' as needed for your locale
  }


function SubsData({ searchResults }) {
    const [modules, setModules] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState('');

    useEffect(() => {
        const fetchCustomers = async () => {
            try {
                execute('GET', `${protectedResources.api.baseEndPoint}/subscriptions`).then(response => {
                  console.log(response);
                  if (response?.success) {                      
                    setModules(response.data);
                    setLoading(false);
                  }else{
                    setError(response.message);
                  }
                });
            } catch (err) {
                setError('Failed to fetch data: ' + err.message);
                setLoading(false);
            }
        };

        fetchCustomers();
    }, []);

    useEffect(() => {
        console.log(searchResults);
        if (searchResults) {
            setModules(searchResults);
        }
    }, [searchResults]);

    if (loading) return <p>Loading...</p>;
    if (error) return <p>Error: {error}</p>;
    
    const dataToRender = searchResults || modules;
    console.log(dataToRender, searchResults, modules);
    return (
        <TableContainer>
            <Table>
                <thead>
                    <TableRow>
                        <TableHeader>Customer</TableHeader>
                        <TableHeader>Organisation</TableHeader>
                        <TableHeader>Active Modules</TableHeader>
                        <TableHeader>Inactive Modules</TableHeader>
                        <TableHeader>Amount Pending</TableHeader>
                        <TableHeader>Next Payment Due</TableHeader>
                    </TableRow>
                </thead>
                <tbody>
                  {console.log(dataToRender)}
                    {dataToRender.map((customer, index) => (
                        <TableRow key={index}>
                            <TableCell>{customer.CustomerName}</TableCell>
                            <TableCell>{customer.OrganizationName}</TableCell>
                            <TableCell>{customer.ActiveModules}</TableCell>
                            <TableCell>{customer.InactiveModules}</TableCell>
                            <TableCell>{customer.AmountToBePaid}</TableCell>
                            <TableCell>{formatDate(customer.NextPaymentDate)}</TableCell>
                        </TableRow>
                    ))}
                </tbody>
            </Table>
        </TableContainer>
    );
}


const TableContainer = styled.div`
// padding: 20px;
// background-color: #f9f9f9;
// border-radius: 8px;
// box-shadow: 0 2px 6px rgba(0,0,0,0.1);
// margin: 20px auto;
// width: 90%;
background-color: #f8f8f8;
border-radius: 0px;
margin: 20px;
width: 100%;
margin-left: 0;
max-height: 350px; /* Set maximum height as desired */
overflow-y: auto; 
box-shadow: 0 2px 4px rgba(0,0,0,0.1);
`;

const Table = styled.table`
width: 100%;
border-collapse: collapse;
margin-top: 20px;

`;

const TableHeader = styled.th`
background-color: #f2f2f2;
color: black;
font-weight: bold;
padding: 12px 15px;
text-align: left;
`;

const TableRow = styled.tr`
&:nth-child(odd) {
background-color: #f2f2f2;
}
&:nth-child(even) {
background-color: #FFFFFF;
}
`;
const up = styled.div`
padding: 20px;
border-radius: 0px;
margin: 20px;
width: 130%;
margin-left: -150px;
box-shadow: 0 2px 4px rgba(0,0,0,0.1);
@media (max-width: 991px) {
flex-wrap: wrap;
`

const TableCell = styled.td`
padding: 20px 15px;
border-bottom: 1px solid #ddd;
`;



export default SubsData;