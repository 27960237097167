import { App, DatePicker, Form, Input, InputNumber, Modal, Space, Switch } from "antd";
import { CloseSquareFilled, SaveFilled } from '@ant-design/icons';
import { useEffect } from "react";
import { protectedResources } from "config/msalConfig";

import execute from "hooks/execute";

export const DataEditor = ({isOpen, dataItem, onDataEditorFinished, onDataEditorCancelled, customer }) => {
    const [form] = Form.useForm();
    const { message } = App.useApp();

    console.log("customer///////////////")
    console.log(customer)

    const handleDataEditFinished = async (values) => {
        console.log("values//////////")
        console.log(values)
        console.log("handleDataEditFinished");
        var newData = {...dataItem, ...values};
        console.log(newData);
        
        if(newData.Id){
            console.log("edit")
            // return;
            // call api to update data
            const response = await execute('PUT',`${protectedResources.api.baseEndPoint}/customerRoles`, newData );
            if(response.success){
                message.success("CustomerRole updated successfully");
                newData = {...newData, ...response.data};
            }else{
                message.error(response.message);
                return;
            }
        }
        else{
            console.log("add")
            // return
            // call  api to add data
            const response = await execute('POST',`${protectedResources.api.baseEndPoint}/customerRoles`, newData);
            if(response.success){   
                message.success("CustomerRole added successfully");
                newData = {...newData, ...response.data};
            }else{
                message.error(response.message);
                return;
            }
        }
        onDataEditorFinished(newData);
    }

    const handleDataEditCancelled = () => {
        console.log("handleDataEditCancelled");
        onDataEditorCancelled();
    }
    
    useEffect(() => {
        if(isOpen){
            form.setFieldsValue(dataItem);
        }
    }, [isOpen, dataItem, form]);

    return (
        <Modal
            open={isOpen}
            title={dataItem.Id ? "Edit Customer Role" : "Add Customer Role"}
            okText={<Space><SaveFilled />Save</Space> }
            cancelText={<Space><CloseSquareFilled />Cancel</Space> }
            okButtonProps={{ autoFocus: true, htmlType: 'submit' }} //form: 'dataEditorForm', key: 'submit'
            onCancel={handleDataEditCancelled}
            destroyOnClose
            modalRender={(dom) => (
                <Form
                  layout="horizontal"
                  form={form}
                  name="dataEditorForm"
                  labelCol={{ span: 8 }}
                  wrapperCol={{ span: 16 }}
                  initialValues={{
                    modifier: 'public',
                  }}
                  clearOnDestroy
                  onFinish={handleDataEditFinished}
                >
                  {dom}
                </Form>
            )}>            
            <Form.Item
                name="Customer_ID"
                label="Customer ID"
                hidden>
                <Input />
            </Form.Item>


            <Form.Item
                name="Name"
                label="Role Name"
                rules={[{ required: true, message: 'Role Name is required' }]}>
                <Input />
            </Form.Item>
            <Form.Item
                name="Status"
                label="Enable"
            >
                <Switch defaultChecked onChange={(checked)=>console.log(checked)} />

            </Form.Item>
           
        </Modal>
    );
};