import { App, Button, Cascader, Checkbox, DatePicker, Form, Input, InputNumber, Modal, Select, Space, Switch } from "antd";
import { CloseSquareFilled, MinusCircleOutlined, PlusOutlined, SaveFilled } from '@ant-design/icons';
import { useEffect, useState } from "react";
import execute from "hooks/execute";
import { protectedResources } from "config/msalConfig";

export const DataEditor = ({isOpen, dataItem, onDataEditorFinished, onDataEditorCancelled }) => {
    const [form] = Form.useForm();
    const { message } = App.useApp();

    const [customers, setCustomers] = useState([]);
    const [etls, setEtls] = useState([]);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState('');


    const fetchCustomers = async () => {
        try {
            setLoading(true);
            const response = await execute('GET', `${protectedResources.api.baseEndPoint}/customers`);
            console.log(response)
            if (response?.success && response?.data.length > 0) {
                console.log("customerData///////////")
                console.log(response.data)

                let result = response.data.map(item => ({
                    value: item.Customer_ID.toString(),
                    label: item.CustEmail
                }));

                setCustomers(result);
                setError('');
            } else {
                setCustomers([]);
                setError(response?.message || 'No customers found for the specified organization');
            }
            setLoading(false);
        } catch (err) {
            console.log(err)
            setError('Failed to fetch data: ' + err.message);
            setLoading(false);
        }
    };

    const fetchETLs = async () => {
        try {
            setLoading(true);
            const response = await execute('GET', `${protectedResources.api.baseEndPoint}/ETL_management/all_etls`);
            
            if (response?.success && response?.data.length > 0) {
                console.log(response)

                const updatedModules = response.data.map(module => {
                    return {
                      ...module,
                      etlData: module.etlData ? JSON.parse(module.etlData).map(item => ({
                        value: item.etlId,
                        label: item.etlName
                      })) : [] // Convert to desired format or return empty array if null
                    };
                  });
                  
                  console.log(updatedModules);
                
                let result = updatedModules.map(item => ({
                    value: item.Id.toString(),
                    label: item.ModuleName,
                    children: item.etlData,
                }));
                console.log("result///////")
                console.log(result)
                setEtls(result);
                
                setError('');
            } else {
                setEtls([]);
                setError(response.message || 'No customers found for the specified organization');
            }
            setLoading(false);
        } catch (err) {
            console.log(err)
            setError('Failed to fetch data: ' + err.message);
            setLoading(false);
        }
    };

    const handleDataEditFinished = async (values) => {
        console.log(values)
        // return;
        values.ETL_id = values.ETL_id[1]
        console.log("handleDataEditFinished");
        var newData = { ...dataItem, ...values };
        console.log(newData);
        // return
        if (newData.Id) {
            console.log("edit")
            // call api to update data
            const response = await execute('PUT', `${protectedResources.api.baseEndPoint}/ETL_management`, newData);
            if (response.success) {
                message.success("Customer updated successfully");
                newData = { ...newData, ...response.data };
            } else {
                message.error(response.message);
                return;
            }
        }
        else {
            console.log("add")
            // return
            // call  api to add data
            const response = await execute('POST', `${protectedResources.api.baseEndPoint}/ETL_management`, newData);
            if (response.success) {
                message.success("Customer added successfully");
                newData = { ...newData, ...response.data };
            } else {
                message.error(response.message);
                return;
            }
        }
        onDataEditorFinished(newData);
    }

    const handleDataEditCancelled = () => {
        console.log("handleDataEditCancelled");
        onDataEditorCancelled();
    }

    useEffect(() => {
        
        console.log(dataItem)
        if (isOpen) {
            fetchCustomers()
            fetchETLs()
            if(dataItem.Id){
                const updated = {...dataItem, customer_id: dataItem.CustomerId.toString(), ETL_id: [dataItem.ModuleId.toString(),dataItem.ETLId]}
                form.setFieldsValue(updated);
            }
            
        }
    }, [isOpen, dataItem ]);

    // useEffect(()=>{
    //     fetchCustomers()
    //     fetchETLs()
    // },[])

    const formItemLayout = {
        labelCol: {
            xs: {
                span: 24,
            },
            sm: {
                span: 4,
            },
        },
        wrapperCol: {
            xs: {
                span: 24,
            },
            sm: {
                span: 20,
            },
        },
    };
    const formItemLayoutWithOutLabel = {
        wrapperCol: {
            xs: {
                span: 24,
                offset: 0,
            },
            sm: {
                span: 20,
                offset: 4,
            },
        },
    };

    return (
        <Modal
            open={isOpen}
            title={dataItem.Id ? "Edit Customer Role" : "Add Customer Role"}
            okText={<Space><SaveFilled />Save</Space>}
            cancelText={<Space><CloseSquareFilled />Cancel</Space>}
            okButtonProps={{ autoFocus: true, htmlType: 'submit' }} //form: 'dataEditorForm', key: 'submit'
            onCancel={handleDataEditCancelled}
            destroyOnClose
            modalRender={(dom) => (
                <Form
                    layout="horizontal"
                    form={form}
                    name="dataEditorForm"
                    labelCol={{ span: 8 }}
                    wrapperCol={{ span: 16 }}
                    initialValues={{
                        modifier: 'public',
                    }}
                    clearOnDestroy
                    onFinish={handleDataEditFinished}
                >
                    {dom}
                </Form>
            )}>
            {/* <Form.Item
                name="ET"
                label="Customer ID"
                hidden>
                <Input />
            </Form.Item> */}


            <Form.Item
                name="customer_id"
                label="Customer"
                rules={[{ required: true, message: 'Customer is required' }]}>
                <Select
                    defaultValue={dataItem.Id ? dataItem.CustomerId : null}
                    showSearch
                    loading = {loading ? true : false}
                    style={{ width: 200 }}
                    placeholder="Search to Select"
                    optionFilterProp="label"
                    options={customers}
                />
            </Form.Item>
            
            <Form.Item
                name="ETL_id"
                label="ETL"
                rules={[{ required: true, message: 'ETL is required' }]}>
                <Cascader
                    defaultValue={dataItem.Id ? dataItem.Name : null}
                    showSearch
                    loading = {loading ? true : false}
                    style={{ width: 200 }}
                    placeholder="Search to Select"
                    optionFilterProp="label"
                    options={etls}
                />
            </Form.Item>
            

        </Modal>
    );
};