import React, { useState } from 'react';
import ModulePageSearch from './ModulePageSearch';
import Data from './data';
import PageHeader from './pageHeader';


function AppLayoutModuleManage({ userDetails }) {
    const [searchResults, setSearchResults] = useState(null); // State to store search results

    return (
        <div
        style={{
            width: "90%",
            marginLeft: "auto",
            marginRight: "auto",
            padding: "20px 0",
          }}
        >
            <PageHeader setSearchResults={setSearchResults}/>
            <Data searchResults={searchResults} />
        </div>
    );
}

export default AppLayoutModuleManage;
