import React, { createContext, useContext, useState } from 'react';
import { useMsal } from '@azure/msal-react';

import execute from "hooks/execute";
import { protectedResources } from '../config/msalConfig';
import { useEffect } from 'react';

const UserContext = createContext();

export const UserProvider = ({ children }) => {
  const { instance } = useMsal();
  let activeAccount;

  if (instance) {
      activeAccount = instance.getActiveAccount();
  }



  const [userDetails, setUserDetails] = useState({});

  useEffect(() => {
    const verifyUser = async (email) => {
      try {
        if(userDetails.email === activeAccount.idTokenClaims.email) {
          return;
        }
        console.log('Verifying user:', email);
        const response = await execute("GET", `${protectedResources.api.baseEndPoint}/adminUsers/${email}`);
        if (!response.data?.isActive) {
          alert(response.message || 'You are not allowed to log in.');
          instance.logoutRedirect({ postLogoutRedirectUri: window.location.origin });
        }else{
          setUserDetails({ ...activeAccount.idTokenClaims, ...response.data });
        }
      } catch (error) {
        console.error('Error verifying user:', error);
        instance.logoutRedirect({ postLogoutRedirectUri: window.location.origin });
      }
    };
    if (activeAccount?.idTokenClaims?.email) {
      console.log('Active account:', activeAccount);
      verifyUser(activeAccount.idTokenClaims.email);
    }
  }, [activeAccount, instance]);

  return (
    <UserContext.Provider value={{ userDetails }}>
      {children}
    </UserContext.Provider>
  );
};

export const useUser = () => useContext(UserContext);
