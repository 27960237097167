import React, { useState } from 'react';
import styled from 'styled-components';



function PageHeader({ fetchRecords }) {
    const [modalIsOpen, setModalIsOpen] = useState(false);
  
    const toggleModal = () => {
      setModalIsOpen(!modalIsOpen);
    };
  
    return (
      <div>
        <Header1>
          <Title>User Management</Title>
        </Header1>
      </div>
    );
  }

  
const Header1 = styled.header`
display: flex;
width: 100%;
justify-content: space-between;
align-items: center;
gap: 10px; // Maintain a reasonable gap between inner elements
padding: 20px; // Adjusted padding for the header
margin-right: -100px; // Ensure this is actually needed, otherwise remove
margin-top: 20px;
margin-left:0;
@media (max-width: 991px) {
  flex-wrap: wrap;
}
`;

const Title = styled.h1`
  margin: 0;
  font-size: 30px;
  font-family: Ubuntu, sans-serif;
  color: var(--bicollab-primary-color-blue-900, #002c57);
`;

const NewOrganizationButton = styled.button`
  padding: 10px 18px;
  font-size: 20px;
  font-family: Ubuntu, sans-serif;
  color: var(--bicollab-black-white-white, #fff);
  background-color: var(--bicollab-secondary-color-orange-700, #f16622);
  border: none;
  border-radius: 4px;
  cursor: pointer;
`;

export default PageHeader;