
import React, { useState } from 'react';
import SubsPageHeader from './SubsPageHeader';
import SubsPageSearch from "./SubsPageSearch";
import SubsData from "./SubsData";


function Subscriptions({ userDetails }) {
    const [searchResults, setSearchResults] = useState(null); // State to store search results

    return (
        <div
        style={{
            width: "90%",
            marginLeft: "auto",
            marginRight: "auto",
            padding: "20px 0",
          }}
        >
            <SubsPageHeader />
            <SubsPageSearch setSearchResults={setSearchResults} />
            <SubsData searchResults={searchResults} />
        </div>
    );
}

export default Subscriptions