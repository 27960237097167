import React from 'react';

import styled from "styled-components";
import Footer from "./Footer";
import Header from './Header';
import { useEffect } from "react";
import { AuthenticatedTemplate, useMsal } from "@azure/msal-react";
import { loginRequest } from '../../config/msalConfig';
import { InteractionStatus } from '@azure/msal-browser';
import { App } from 'antd';
import Sidebar from './Sidebar';

export const PageLayout = ( {children, userDetails} ) => {
    const { instance, inProgress } = useMsal();
    let activeAccount;

    if (instance) {
        activeAccount = instance.getActiveAccount();
    }
    
    useEffect(() => {
      if(!activeAccount && inProgress === InteractionStatus.None){
        instance.loginRedirect(loginRequest).catch((error) => console.log(error));            
      }
    }, [inProgress, instance, activeAccount]);

    return (
      <AuthenticatedTemplate>
        <AppContainer className='min-h-[97]'>
          <App style={{
                "min-height": "100vh",

          }}>
            <Header userDetails={userDetails} />
            <RowContainer style={{
                "min-height": "91vh",
                width: "100%",
                gap: 0,
            }}>
              <Sidebar />
              <Columnn>
                  {children}
              </Columnn>
            </RowContainer>
          </App>
          <Footer />
        </AppContainer>     
      </AuthenticatedTemplate>       
    );
}
const Columnn = styled.div`
  display: flex;
  flex-direction: column;
  width: calc(100% - 342px);
  gap: 20px; // Adjust the vertical gap between MyComponent1 and MyComponent3
`;


const AppContainer = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh; 
  gap: 0px; 
`;

const RowContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: 90%;
  gap: 180px; // Adjust the gap between components as needed
  height: 100%; // Set the height to 100% of its parent
  flex-grow: 1; // Allow it to expand to fill available space
  @media (max-width: 991px) {
    flex-direction: column; // Stack them on smaller screens
  }
`;





export default PageLayout;