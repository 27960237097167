import React, { useState, useEffect } from 'react';
import execute from "hooks/execute";
import { protectedResources } from 'config/msalConfig';
import UserPageSearch from './UserPageSearch';
import Data from './data';
import PageHeader from './pageHeader';


function UserManager({ userDetails }) {
  const [searchResults, setSearchResults] = useState(null);

  const fetchRecords = async () => {
    console.log('Fetching data...');
    try {
      const response = await execute('GET', `${protectedResources.api.baseEndPoint}/adminUsers`);
      if(response){
        if (response.success) {
          setSearchResults(response.data);
        } else {
          console.log('Error fetching data:', response.error);
        }        
      }else{
        console.log('response is undefined', response);
      }

    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  useEffect(() => {
    // fetchRecords();
  }, []);

  useEffect(() => { 
      console.log('searchResults:', searchResults);
  }, [searchResults]);

  return (
      <div 
        style={{
          width: "90%",
          marginLeft: "auto",
          marginRight: "auto",
          padding: "20px 0",
        }}
      >
      <PageHeader fetchRecords={fetchRecords} />
      {/* <UserPageSearch setSearchResults={setSearchResults} /> */}
      <Data searchResults={searchResults} fetchRecords={fetchRecords} />
      </div>
  );
}

export default UserManager;