import styled from "styled-components";
import { useEffect, useState } from 'react';
import execute from "hooks/execute";
import { protectedResources } from 'config/msalConfig';
import { Button, Col, Form, Input, Row, Select } from "antd";


function CustomerRoleFilter({ setSearchResults, organizationId, setOrganizationId, customerId, setCustomerId, moduleId, setModuleId, handleSearch }) {


    const [form] = Form.useForm();

    const [loading, setLoading] = useState(true);
    const [error, setError] = useState('');



    const [organizations, setOrganizations] = useState([]);
    const [organizationsWithModules, setOrganizationsWithModules] = useState([]);
    const [customers, setCustomers] = useState([]);
    const [modules, setModules] = useState([]);


    const fetchOrganizations = async () => {
        try {
            const response = await execute('GET', `${protectedResources.api.baseEndPoint}/organizations`);
            if(response){
                if (response.success) { 
                    console.log(response.data) 
                    setOrganizationsWithModules(response.data)
                    const result = response.data.map(item => ({
                        value: item.Org_ID.toString(),
                        label: item.Org_Name
                    }));

                    setOrganizations(result);
                    setCustomers([])
                    setModules([])
                    setLoading(false);
                    // setSearchResults(response.data.customers); // Assuming customers are part of the response
                }else{
                    setError(response.message || 'No organization found for the specified name');
                    setLoading(false);
                }
            }
        } catch (err) {
            setError('Failed to fetch data: ' + err.message);
            setLoading(false);
        }
    };

    const fetchCustomers = async (orgId) => {
        try {
            // return;
            const response = await execute('GET', `${protectedResources.api.baseEndPoint}/organizations/${orgId}/customers`);
            if(response){
                if (response.success) { 
                    console.log(response.data)



                    let result = response.data.map(item => ({
                        value: item.Customer_ID.toString(),
                        label: item.CustEmail
                    }));

                    

                    setCustomers(result);
                    setLoading(false);
                    // setSearchResults(response.data.customers); // Assuming customers are part of the response
                }else{
                    setError(response.message || 'No organization found for the specified name');
                    setLoading(false);
                }
            }
        } catch (err) {
            console.log(err)
            setError('Failed to fetch data: ' + err.message);
            setLoading(false);
        }
    };

    const handleOrganizationChange = async (value) => {
        console.log(value)
        setOrganizationId(value)
        fetchCustomers(value)
        fetchModules(value)
    };

    const fetchModules = async (orgId) => {
        console.log(organizations)
        try {
            // return;
            const response = await execute('GET', `${protectedResources.api.baseEndPoint}/modules`);
            if(response){
                if (response.success) { 
                    console.log(response.data) 
                    
                    // Find the organization with the given Org_ID
                    const selectedOrg = organizationsWithModules.find(org => org.Org_ID == orgId);
                    console.log(selectedOrg)
                    if (selectedOrg) {
                        // Extract the ShortNames from the Modules key
                        const moduleShortNames = selectedOrg.Modules.split(", ").map(code => code.trim());

                        // Filter module data for the matching ShortNames
                        const filteredModules = response.data.filter(module =>
                            moduleShortNames.includes(module.ShortName)
                        );

                        let result = filteredModules.map(item => ({
                            value: item.Id.toString(),
                            label: item.ModuleName
                        }));

                        // result = [{value:"0",label:"All Modules"}, ...result]

                        console.log(result)
    
                        setModules(result);

                        console.log(filteredModules);
                    } else {
                        console.log(`Organization with Org_ID ${orgId} not found.`);
                    }

                    
                    setLoading(false);
                    // setSearchResults(response.data.customers); // Assuming customers are part of the response
                }else{
                    setError(response.message || 'No organization found for the specified name');
                    setLoading(false);
                }
            }
        } catch (err) {
            console.log(err)
            setError('Failed to fetch data: ' + err.message);
            setLoading(false);
        }
    };

    const handleCustomerChange = async (value) => {
        console.log(value)
        setCustomerId(value)
        // fetchModules(value)
    };

    useEffect(() => {
        
        fetchOrganizations()
        
    }, []);

    

    // const handleSearch = () => {
    //     console.log('Sending search parameters:', { organization, customer });

    //     execute('GET', `${protectedResources.api.baseEndPoint}/customers?${new URLSearchParams({
    //         organization,
    //         customer
    //     })}`)
    //         .then(response => {
    //             setSearchResults(response.data);
    //             console.log('Search Results:', response.data);
    //         })
    //         .catch(error => {
    //             console.error('Error fetching data:', error);
    //         });
    // };

    // const handleOrganizationChange = (e) => {
    //     console.log('Event object:', e);
    //     console.log('Customer input:', e.target.value);
    //     setOrganization(e.target.value);
    // };

    // const handleCustomerChange = (e) => {
    //     console.log('Event object:', e);
    //     console.log('Customer input:', e.target.value);
    //     setCustomer(e.target.value);
    // };

    return (
        <SearchParameterContainer>
            <SearchParameterTitle>Search Parameter</SearchParameterTitle>
            <Form
                layout="vertical"
                onFinish={handleSearch}
                form={form}
                initialValues={{ layout: "vertical" }}
                className="flex"
                style={{ display: "flex", gap: "16px", alignItems: "flex-end" }}
            >
                <Form.Item name="organization_id" label="Organization" style={{ flex: 1 }}>
                    <Select
                        showSearch
                        loading={loading}
                        placeholder="Select Organization"
                        filterOption={(input, option) =>
                            (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                        }
                        options={organizations}
                        onChange={handleOrganizationChange}
                    />
                </Form.Item>
                <Form.Item name="customer_id" label="Customer" style={{ flex: 1 }}>
                    <Select
                        showSearch
                        loading={loading}
                        placeholder="Select Customer"
                        filterOption={(input, option) =>
                            (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                        }
                        options={customers}
                        onChange={handleCustomerChange}
                    />
                </Form.Item>
                <Form.Item name="module_id" label="Module" style={{ flex: 1 }}>
                    <Select
                        showSearch
                        loading={loading}
                        placeholder="Select Module"
                        filterOption={(input, option) =>
                            (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                        }
                        options={modules}
                        onChange={(value)=>setModuleId(value)}
                    />
                </Form.Item>
                <Form.Item>
                    <Button type="primary" htmlType="submit">Search</Button>
                </Form.Item>
            </Form>
            {/* <Row gutter={10}>
                <Col className="gutter-row" span={11}>
                    <InputField
                        label="Customer"
                        placeholder="Enter a name or email address"
                        value={organization}
                        onChange={handleOrganizationChange}
                    />
                </Col>
                <Col className="gutter-row" span={11}>
                    <InputField
                        label="Workspace"
                        placeholder="Enter a workspace name"
                        value={customer}
                        onChange={handleCustomerChange}
                    />
                </Col>
                <Col className="gutter-row" span={2}>
                    <SearchButton onClick={handleSearch}>Search</SearchButton>
                </Col>
            </Row> */}
        </SearchParameterContainer>
    );
}

function InputField({ label, placeholder, value, onChange }) {
    return (
        <InputFieldContainer>
            <InputFieldLabel>{label}</InputFieldLabel>
            <InputFieldWrapper
                type="text"  // Specify the type if necessary, or make it a prop if you need different types
                placeholder={placeholder}
                value={value}  // Bind the input value to the value prop
                onChange={onChange}  // Propagate the onChange event to the parent component
            />
        </InputFieldContainer>
    );
}



const InputFieldLabel = styled.label`
    color: #767676;
    text-align: left;
    font: 500 14px Ubuntu, sans-serif;
`;

const InputFieldWrapper = styled.input`
    border-radius: 2px;
    border: 1px solid rgba(187, 187, 188, 1);
    background-color: var(--bicollab-black-white-white, #fff);
    margin-top: 13px;
    padding: 10px 21px;
    color: var(--bicollab-black-white-black-300, #bbbbbc);
    font: 400 16px Ubuntu, sans-serif;
    
    @media (max-width: 991px) {
        padding: 0 20px;
    }
`;

const InputFieldContainer = styled.div`
    flex: 1;
    display: flex;
    flex-direction: column;
    padding: 0 5px;
`;

const SearchParameterContainer = styled.section`
    padding: 20px;
    background-color: #f8f8f8;
    border-radius: 0px;
    margin: 20px;
    width: 125%;
    margin-left: -150px;
    box-shadow: 0 2px 4px rgba(0,0,0,0.1);
    margin-top:-20px;
`;

const SearchParameterTitle = styled.h2`
    color: var(--bicollab-primary-color-blue-900, #002c57);
    letter-spacing: 0.4px;
    font: 500 16px/100% Ubuntu, sans-serif;
    
    @media (max-width: 991px) {
        max-width: 100%;
    }
`;

const SearchParameterFields = styled.div`
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    gap: 10px;
    margin-top: 24px;
`;

const SearchButton = styled.button`
    border-radius: 4px;
    background-color: var(--bicollab-secondary-color-orange-700, #f16622);
    align-self: flex-end;
    margin-top: 28px;
    padding: 10px 18px;
    color: var(--bicollab-black-white-white, #fff);
    white-space: nowrap;
    font: 500 20px Ubuntu, sans-serif;
    
    @media (max-width: 991px) {
        white-space: initial;
        padding: 0 20px;
    }
`;
export default CustomerRoleFilter;