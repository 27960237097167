
import React, { useEffect } from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { useMsal } from '@azure/msal-react';
import { loginRequest } from '../../config/msalConfig';
import { InteractionStatus } from '@azure/msal-browser';
const PrivateRoute = ({ element: Component }) => {
    const location = useLocation();
    const { instance, inProgress } = useMsal();

    let activeAccount;

    if (instance) {
        activeAccount = instance.getActiveAccount();
    }

    useEffect(() => {
        if(!activeAccount && [InteractionStatus.None, InteractionStatus.Login].includes(inProgress)){
            console.log("PrivateRoute: redirecting to login screen");
            instance.loginRedirect(loginRequest).catch((error) => console.log(error));      
            return;      
        }
        console.log("PrivateRoute: ", activeAccount, inProgress,[InteractionStatus.None, InteractionStatus.Login].includes(inProgress));
    }, [inProgress, activeAccount]);

    return activeAccount ? (
        React.createElement(Component)
    ) : (
        <Navigate to="/" state={{ from: location }} />
    );
};

export default PrivateRoute;