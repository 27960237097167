import React, {  useMemo } from 'react';
import styled from "styled-components";
import { useNavigate } from 'react-router-dom';
import { Descriptions } from "antd";

function CustomerRoleHeader() {
      
    const navigate = useNavigate();


    const handleGoBack = () => {
        navigate(-1); // Navigate back to the previous page
    };

    // const orgDescriptions = useMemo(()=>{
    //     return [
    //         {
    //             key: '1',
    //             label: 'Name',
    //             children: organization.Org_Name
    //         },
    //         {
    //             key: '2',
    //             label: 'Address',
    //             children: organization.Org_Address
    //         },
    //         {
    //             key: '3',
    //             label: 'Email',
    //             children: organization.Org_Email
    //         },
    //         {
    //             key: '4',
    //             label: 'Theme',
    //             children: 'Red'
    //         }
    //     ];
    // }, [organization]);

    return (
        <MainCont>
            <TitleContainer>
                <Title>Customer Role Management</Title>                
            </TitleContainer>            
        </MainCont>
    );
}



const TitleContainer = styled.div`
    // position: sticky;
    // top: 0;
    // z-index: 1;
    // display: flex;
    // justify-content: space-between;
    // align-items: center;
    // padding: 10px 20px;
    // margin-top:-10px;
    // margin-right: -10px;
`;

const Title = styled.h1`
    margin: 0;
    font-size: 25px;
    font-family: Ubuntu, sans-serif;
    color: var(--bicollab-primary-color-blue-900, #002c57);
`;

const MainCont = styled.div`
    padding: 20px;
    border-radius: 0px;
    margin: 20px 0px 20px 0px;
    width: 100%;
    margin-left: 0;
    padding-bottom:5px;
`;

const BackButton = styled.button`
font-size: 20px;
font-family: Ubuntu, sans-serif;
color: #ff9900;
margin-left:-6px;
background-color: transparent;
border: none;
cursor: pointer;
text-transform: normal;
letter-spacing: 1px;
transition: color 0.3s ease;

&:hover {
    color: #ff6600;
}

&:focus {
    outline: 2;
}
`;

const DetailsContainer = styled.div`
    padding: 20px;
    border-radius: 0px;
    margin: 20px;
    width: 100%;
    margin-left: 3px;
   
    padding-bottom:5px;
    box-shadow: 0 2px 4px rgba(0,0,0,0.1);
`;

const DetailsInfo = styled.div`
    align-items: left;
    justify-content: center;
    margin-top: 20px;
    margin-left: -10px;
    font-size:20px;
`;

const Detail = styled.span`
    margin: 0 10px;
`;

const Separator = styled.span`
    color: #ccc;
    margin: 0 5px;
`;

export default CustomerRoleHeader;